import React, { Component } from 'react'
import SerabeNFT from '../../abis/SerabeNFT.json'
import SerabeNFTSale from '../../abis/SerabeNFTSale.json'
import Serabe from '../../abis/Serabe.json'
import SerabeVerified from '../../abis/SerabeVerified.json'
import SerabeNFTLikes from '../../abis/SerabeNFTLikes.json'

import MetaTags from 'react-meta-tags'

import ReactPlaceholder from 'react-placeholder'
import {
  TextBlock,
  MediaBlock,
  TextRow,
  RectShape,
  RoundShape,
} from 'react-placeholder/lib/placeholders'
import 'react-placeholder/lib/reactPlaceholder.css'

import SerabeAvatars from '../../abis/SerabeAvatars.json'
import Jazzicon, { jsNumberForAddress } from 'react-jazzicon'

import LoadingOverlay from 'react-loading-overlay'

import { TwitterTweetButton } from 'react-social-sharebuttons'

import '@google/model-viewer'
// import '@google/model-viewer/dist/model-viewer';

import Web3 from 'web3'

class Fantums extends Component {
  render() {
    const nft_id_path = window.location.href.split('/')
    // const nft_id_path = window.location.hash.split('/')
    const key = nft_id_path[nft_id_path.length - 1]

    return (
      <div>
        <MetaTags>
          <title>
            Fantums #{key} - {this.state.imageData_name}
          </title>
          <meta
            name="description"
            content="Fantums are generatively created art, each based on a completely unique Keccak-256 hash. These hashes are also mined during the Fantum Finding process."
          />
          <meta
            property="og:title"
            content={'FUM NFT #' + key + ' - ' + this.state.imageData_name}
          />
          <meta
            property="og:description"
            content="Fantums are generatively created art, each based on a completely unique Keccak-256 hash. These hashes are also mined during the Fantum Finding process."
          />
          <meta property="og:image" content={this.state.imageData_image} />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content="@agin_webdev" />
          <meta
            name="twitter:title"
            content={'FUM NFT #' + key + ' - ' + this.state.imageData_name}
          />
          <meta
            name="twitter:description"
            content="Fantums are generatively created art, each based on a completely unique Keccak-256 hash. These hashes are also mined during the Fantum Finding process."
          />
          <meta name="twitter:image" content={this.state.imageData_image} />
        </MetaTags>
        <LoadingOverlay
          active={this.state.txpend}
          spinner
          transition={false}
          text={'Waiting on...' + this.state.txs + ' transaction(s)'}
          styles={{
            overlay: base => ({
              ...base,
              background: 'rgba(0, 0, 0, 0.95)',
              position: 'fixed',
            }),
            wrapper: {
              width: '100%',
              height: '100%',
              borderRadius: '25px',
            },
          }}
        >
          <div className="head-title col-auto mx-4">
            <h4 className="mb-0 font-weight-normal">Fantums #{key} Details</h4>
          </div>
          <div className="nft-detail-adj">
            <div className="">
              <div className="row">
                <div className="col-md-6">
                  <ReactPlaceholder
                    type="rect"
                    ready={this.state.imageData_image && this.state.ready}
                    showLoadingAnimation={true}
                    color="#333"
                    style={{
                      width: '100%',
                      height: '100%',
                      borderRadius: '15px',
                    }}
                  >
                    <h1 className="text-light" align="center">
                      <strong>{this.state.imageData_name}</strong>
                    </h1>
                    <p
                      className="text-light"
                      align="center"
                      style={{
                        backgroundColor: '#000',
                        padding: '20px',
                        borderRadius: '15px',
                      }}
                    >
                      "Fantums are generatively created art, each based on a
                      completely unique Keccak-256 hash. These hashes are also
                      mined during the Fantum Finding process."
                    </p>
                    <div
                      className="max-400"
                      style={{ width: '98%', margin: '0 auto' }}
                      align="center"
                    >
                      <img
                        alt="NFT"
                        className="homeimage shadow-lg rounded"
                        src={this.state.imageData_image}
                        style={{ background: '#222', maxWidth: '800px' }}
                      />
                    </div>

                    <br />
                  </ReactPlaceholder>
                </div>
                <div className="col-md-6">
                  <ReactPlaceholder
                    type="rect"
                    ready={this.state.loaded}
                    showLoadingAnimation={true}
                    color="#333"
                    style={{
                      width: '60%',
                      height: '150px',
                      margin: '15px auto',
                      borderRadius: '15px',
                    }}
                  >
                    <div
                      className="d-flex justify-content-center align-items-center text-light"
                      id="giftNFT"
                    >
                      <div className="mx-2">
                        {this.state.owner === this.state.account &&
                        this.state.loaded === true ? (
                          <form
                            onSubmit={event => {
                              event.preventDefault()
                              this.giftNFT(key)
                            }}
                          >
                            <br />
                            <div className="d-flex">
                              <input
                                type="text"
                                className="form-control mx-1"
                                placeholder="Harmony Address 0x..."
                                onChange={event =>
                                  this.setState({
                                    accttosend: event.target.value,
                                  })
                                }
                              />
                              <input
                                type="submit"
                                className="btn btn-block btn-primary rounded-0 mx-1 buybtn"
                                value="Gift This NFT!"
                                disabled={
                                  !this.state.blacklisted ? false : true
                                }
                              />
                            </div>
                          </form>
                        ) : null}
                      </div>
                    </div>
                    <br />
                    {this.state.owner === this.state.account &&
                    this.state.loaded === true ? (
                      <div align="center">OR</div>
                    ) : null}
                    <br />
                    <div
                      className="d-flex justify-content-center align-items-center my-1 text-light"
                      id="approvedsale"
                    >
                      {this.state.owner === this.state.account &&
                      this.state.loaded === true ? (
                        <form
                          onSubmit={event => {
                            event.preventDefault()
                            this.approveNFT(key)
                          }}
                        >
                          <div className="d-flex">
                            <div className="w-75 my-2 text-secondary">
                              Price in SEBI
                            </div>
                            <input
                              type="text"
                              className="form-control mx-1"
                              placeholder="New Price in SEBI"
                              defaultValue={this.state.imageData_price}
                              onChange={event =>
                                this.setState({ new_price: event.target.value })
                              }
                            />
                            <input
                              type="submit"
                              className="btn btn-block btn-primary rounded-0 mx-1 buybtn"
                              value={
                                !this.state.approved
                                  ? 'Approve & List for Sale'
                                  : 'Update & Relist'
                              }
                              disabled={!this.state.blacklisted ? false : true}
                            />
                          </div>
                        </form>
                      ) : null}
                    </div>

                    <div
                      className="d-flex justify-content-center align-items-center text-light"
                      id="buyanNFT"
                    >
                      <div className="mx-2">
                        {this.state.approved &&
                        this.state.owner !== this.state.account &&
                        this.state.loaded === true ? (
                          !this.state.blacklisted ? (
                            <form
                              onSubmit={event => {
                                event.preventDefault()
                                this.buySebi(key)
                              }}
                            >
                              {/* <input
                                                type='submit'
                                                className='btn btn-block btn-primary rounded-0'
                                                value={"Buy - " + this.state.imageData_price[key] + " SEBI"}
                                            /> */}
                              <button
                                type="submit"
                                className="btn btn-block btn-primary rounded-15 text-light p-3 buybtn"
                                disabled={
                                  typeof this.state.account !== 'undefined' &&
                                  !this.state.blacklisted &&
                                  this.state.connected === true
                                    ? false
                                    : true
                                }
                              >
                                <strong>BUY WITH SEBI</strong>
                                <br />
                                {this.state.imageData_price}&nbsp;
                                <img
                                  alt="main"
                                  height="24px"
                                  src="../logo.png"
                                />{' '}
                                SEBI
                                <br />( ≈ ${this.state.imageData_usdprice}{' '}
                                <small>USD</small>)
                              </button>
                            </form>
                          ) : (
                            <h2 className="text-danger">BLACKLISTED NFT</h2>
                          )
                        ) : null}
                      </div>
                      <div className="mx-2">
                        {this.state.approved &&
                        this.state.owner !== this.state.account &&
                        this.state.loaded === true ? (
                          !this.state.blacklisted ? (
                            <form
                              onSubmit={event => {
                                event.preventDefault()
                                this.buySerabe(key)
                              }}
                            >
                              {/* <input
                                                type='submit'
                                                className='btn btn-block btn-primary rounded-0'
                                                value={"Buy - " + this.state.imageData_price[key] + " SEBI"}
                                            /> */}
                              <button
                                type="submit"
                                className="btn btn-block btn-primary rounded-15 text-light p-3 buybtn"
                                disabled={
                                  typeof this.state.account !== 'undefined' &&
                                  !this.state.blacklisted &&
                                  this.state.connected === true
                                    ? false
                                    : true
                                }
                              >
                                <img
                                  alt="main"
                                  height="28px"
                                  src="../ftmlogo.png"
                                />
                                <br />
                                <strong>BUY WITH ONE!</strong>
                                <br />
                                {this.state.connected === true ? (
                                  <>
                                    ≈ {this.state.imageData_slippage}{' '}
                                    <small>ONE</small>
                                  </>
                                ) : (
                                  <div>Connect Wallet</div>
                                )}
                              </button>
                            </form>
                          ) : null
                        ) : null}
                      </div>
                    </div>

                    <div className="d-flex justify-content-center align-items-center my-1">
                      {!this.state.approved &&
                      this.state.owner !== this.state.account &&
                      this.state.loaded === true ? (
                        <div className="text-danger">
                          {'This NFT is not approved for sale by the owner'}
                        </div>
                      ) : null}
                    </div>
                    <br />
                    <br />
                    <div className="row">
                      <div className="col-md-6" align="center">
                        <div
                          style={{
                            color: 'white',
                            marginBottom: '5px',
                            fontWeight: 'bold',
                          }}
                        >
                          Owner
                        </div>
                        <ReactPlaceholder
                          type="rect"
                          ready={this.state.readyowner}
                          showLoadingAnimation={true}
                          color="#333"
                          style={{
                            width: '100px',
                            height: '100px',
                            marginTop: '10px',
                            borderRadius: '15px',
                          }}
                        >
                          {this.state.owner !== null ? (
                            this.state.ipfs !== '' &&
                            (this.state.mim === 'image/jpeg' ||
                              this.state.mim === 'image/png' ||
                              this.state.mim === 'image/gif') ? (
                              <a href={'/collection/' + this.state.owner}>
                                <div
                                  style={{
                                    position: 'relative',
                                    width: '75px',
                                  }}
                                >
                                  <img
                                    src={
                                      'https://cloudflare-ipfs.com/ipfs/' +
                                      this.state.ipfs
                                    }
                                    alt=""
                                    border="0"
                                    height="75px"
                                    width="75px"
                                    style={{
                                      borderRadius: '50%',
                                      marginBottom: '5px',
                                    }}
                                  />
                                  {this.state.owner_verified === true ? (
                                    <div
                                      style={{
                                        position: 'absolute',
                                        bottom: '5px',
                                        right: '5px',
                                      }}
                                    >
                                      <svg
                                        width="16"
                                        height="16"
                                        viewBox="0 0 12 12"
                                        fill="#4E78FF"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M4.78117 0.743103C5.29164 -0.247701 6.70826 -0.247701 7.21872 0.743103C7.52545 1.33846 8.21742 1.62509 8.8553 1.42099C9.91685 1.08134 10.9186 2.08304 10.5789 3.1446C10.3748 3.78247 10.6614 4.47445 11.2568 4.78117C12.2476 5.29164 12.2476 6.70826 11.2568 7.21872C10.6614 7.52545 10.3748 8.21742 10.5789 8.8553C10.9186 9.91685 9.91685 10.9186 8.8553 10.5789C8.21742 10.3748 7.52545 10.6614 7.21872 11.2568C6.70826 12.2476 5.29164 12.2476 4.78117 11.2568C4.47445 10.6614 3.78247 10.3748 3.1446 10.5789C2.08304 10.9186 1.08134 9.91685 1.42099 8.8553C1.62509 8.21742 1.33846 7.52545 0.743103 7.21872C-0.247701 6.70826 -0.247701 5.29164 0.743103 4.78117C1.33846 4.47445 1.62509 3.78247 1.42099 3.1446C1.08134 2.08304 2.08304 1.08134 3.1446 1.42099C3.78247 1.62509 4.47445 1.33846 4.78117 0.743103Z"
                                          fill="#FFF"
                                        ></path>
                                        <path
                                          fillRule="evenodd"
                                          clipRule="evenodd"
                                          d="M8.43961 4.23998C8.64623 4.43922 8.65221 4.76823 8.45297 4.97484L5.40604 8.13462L3.54703 6.20676C3.34779 6.00014 3.35377 5.67113 3.56039 5.47189C3.76701 5.27266 4.09602 5.27864 4.29526 5.48525L5.40604 6.63718L7.70475 4.25334C7.90398 4.04672 8.23299 4.04074 8.43961 4.23998Z"
                                          fill="#000"
                                        ></path>
                                      </svg>
                                    </div>
                                  ) : null}
                                </div>
                              </a>
                            ) : (
                              <a href={'/collection/' + this.state.owner}>
                                <div
                                  style={{
                                    position: 'relative',
                                    width: '75px',
                                  }}
                                >
                                  <Jazzicon
                                    diameter={75}
                                    seed={jsNumberForAddress(this.state.owner)}
                                  />
                                  {this.state.owner_verified === true ? (
                                    <div
                                      style={{
                                        position: 'absolute',
                                        bottom: '5px',
                                        right: '5px',
                                      }}
                                    >
                                      <svg
                                        width="16"
                                        height="16"
                                        viewBox="0 0 12 12"
                                        fill="#4E78FF"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M4.78117 0.743103C5.29164 -0.247701 6.70826 -0.247701 7.21872 0.743103C7.52545 1.33846 8.21742 1.62509 8.8553 1.42099C9.91685 1.08134 10.9186 2.08304 10.5789 3.1446C10.3748 3.78247 10.6614 4.47445 11.2568 4.78117C12.2476 5.29164 12.2476 6.70826 11.2568 7.21872C10.6614 7.52545 10.3748 8.21742 10.5789 8.8553C10.9186 9.91685 9.91685 10.9186 8.8553 10.5789C8.21742 10.3748 7.52545 10.6614 7.21872 11.2568C6.70826 12.2476 5.29164 12.2476 4.78117 11.2568C4.47445 10.6614 3.78247 10.3748 3.1446 10.5789C2.08304 10.9186 1.08134 9.91685 1.42099 8.8553C1.62509 8.21742 1.33846 7.52545 0.743103 7.21872C-0.247701 6.70826 -0.247701 5.29164 0.743103 4.78117C1.33846 4.47445 1.62509 3.78247 1.42099 3.1446C1.08134 2.08304 2.08304 1.08134 3.1446 1.42099C3.78247 1.62509 4.47445 1.33846 4.78117 0.743103Z"
                                          fill="#FFF"
                                        ></path>
                                        <path
                                          fillRule="evenodd"
                                          clipRule="evenodd"
                                          d="M8.43961 4.23998C8.64623 4.43922 8.65221 4.76823 8.45297 4.97484L5.40604 8.13462L3.54703 6.20676C3.34779 6.00014 3.35377 5.67113 3.56039 5.47189C3.76701 5.27266 4.09602 5.27864 4.29526 5.48525L5.40604 6.63718L7.70475 4.25334C7.90398 4.04672 8.23299 4.04074 8.43961 4.23998Z"
                                          fill="#000"
                                        ></path>
                                      </svg>
                                    </div>
                                  ) : null}
                                </div>
                              </a>
                            )
                          ) : null}

                          <p style={{ color: 'white' }}>
                            {this.state.owner !== null
                              ? this.state.name === ''
                                ? this.state.owner.substring(0, 8) + '...'
                                : this.state.name
                              : null}{' '}
                            <a href={'/collection/' + this.state.owner}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-arrow-up-right-square-fill"
                                viewBox="0 0 16 16"
                              >
                                <path d="M14 0a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h12zM5.904 10.803 10 6.707v2.768a.5.5 0 0 0 1 0V5.5a.5.5 0 0 0-.5-.5H6.525a.5.5 0 1 0 0 1h2.768l-4.096 4.096a.5.5 0 0 0 .707.707z" />
                              </svg>
                            </a>
                          </p>
                        </ReactPlaceholder>
                        <br />
                      </div>
                      <div className="col-md-6" align="center">
                        <div
                          style={{
                            color: 'white',
                            marginBottom: '5px',
                            fontWeight: 'bold',
                          }}
                        >
                          Creator
                        </div>
                        <ReactPlaceholder
                          type="rect"
                          ready={this.state.readyminter}
                          showLoadingAnimation={true}
                          color="#333"
                          style={{
                            width: '100px',
                            height: '100px',
                            marginTop: '10px',
                            borderRadius: '15px',
                          }}
                        >
                          {// TODO: GANTI FANTUMS
                          this.state.mimmint === 'image/jpeg' ||
                          this.state.mimmint === 'image/png' ||
                          this.state.mimmint === 'image/gif' ? (
                            <a href="https://fantums.com" target="_blank">
                              <div
                                style={{ position: 'relative', width: '75px' }}
                              >
                                <img
                                  src={
                                    'https://api.fantums.com/token/img/0x00898416d26e57c6bf49b3ec285004ca1b7f8ea2e6b94dbdd8a27cd671384dcc.png'
                                  }
                                  alt=""
                                  border="0"
                                  height="75px"
                                  width="75px"
                                  style={{
                                    borderRadius: '50%',
                                    marginBottom: '5px',
                                  }}
                                />
                                {this.state.mint_verified === true ? (
                                  <div
                                    style={{
                                      position: 'absolute',
                                      bottom: '5px',
                                      right: '5px',
                                    }}
                                  >
                                    <svg
                                      width="16"
                                      height="16"
                                      viewBox="0 0 12 12"
                                      fill="#4E78FF"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M4.78117 0.743103C5.29164 -0.247701 6.70826 -0.247701 7.21872 0.743103C7.52545 1.33846 8.21742 1.62509 8.8553 1.42099C9.91685 1.08134 10.9186 2.08304 10.5789 3.1446C10.3748 3.78247 10.6614 4.47445 11.2568 4.78117C12.2476 5.29164 12.2476 6.70826 11.2568 7.21872C10.6614 7.52545 10.3748 8.21742 10.5789 8.8553C10.9186 9.91685 9.91685 10.9186 8.8553 10.5789C8.21742 10.3748 7.52545 10.6614 7.21872 11.2568C6.70826 12.2476 5.29164 12.2476 4.78117 11.2568C4.47445 10.6614 3.78247 10.3748 3.1446 10.5789C2.08304 10.9186 1.08134 9.91685 1.42099 8.8553C1.62509 8.21742 1.33846 7.52545 0.743103 7.21872C-0.247701 6.70826 -0.247701 5.29164 0.743103 4.78117C1.33846 4.47445 1.62509 3.78247 1.42099 3.1446C1.08134 2.08304 2.08304 1.08134 3.1446 1.42099C3.78247 1.62509 4.47445 1.33846 4.78117 0.743103Z"
                                        fill="#FFF"
                                      ></path>
                                      <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M8.43961 4.23998C8.64623 4.43922 8.65221 4.76823 8.45297 4.97484L5.40604 8.13462L3.54703 6.20676C3.34779 6.00014 3.35377 5.67113 3.56039 5.47189C3.76701 5.27266 4.09602 5.27864 4.29526 5.48525L5.40604 6.63718L7.70475 4.25334C7.90398 4.04672 8.23299 4.04074 8.43961 4.23998Z"
                                        fill="#000"
                                      ></path>
                                    </svg>
                                  </div>
                                ) : null}
                              </div>
                            </a>
                          ) : (
                            <a href="https://fantums.com" target="_blank">
                              <div
                                style={{ position: 'relative', width: '75px' }}
                              >
                                <Jazzicon
                                  diameter={75}
                                  seed={jsNumberForAddress(this.state.minter)}
                                />
                                {this.state.mint_verified === true ? (
                                  <div
                                    style={{
                                      position: 'absolute',
                                      bottom: '5px',
                                      right: '5px',
                                    }}
                                  >
                                    <svg
                                      width="16"
                                      height="16"
                                      viewBox="0 0 12 12"
                                      fill="#4E78FF"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M4.78117 0.743103C5.29164 -0.247701 6.70826 -0.247701 7.21872 0.743103C7.52545 1.33846 8.21742 1.62509 8.8553 1.42099C9.91685 1.08134 10.9186 2.08304 10.5789 3.1446C10.3748 3.78247 10.6614 4.47445 11.2568 4.78117C12.2476 5.29164 12.2476 6.70826 11.2568 7.21872C10.6614 7.52545 10.3748 8.21742 10.5789 8.8553C10.9186 9.91685 9.91685 10.9186 8.8553 10.5789C8.21742 10.3748 7.52545 10.6614 7.21872 11.2568C6.70826 12.2476 5.29164 12.2476 4.78117 11.2568C4.47445 10.6614 3.78247 10.3748 3.1446 10.5789C2.08304 10.9186 1.08134 9.91685 1.42099 8.8553C1.62509 8.21742 1.33846 7.52545 0.743103 7.21872C-0.247701 6.70826 -0.247701 5.29164 0.743103 4.78117C1.33846 4.47445 1.62509 3.78247 1.42099 3.1446C1.08134 2.08304 2.08304 1.08134 3.1446 1.42099C3.78247 1.62509 4.47445 1.33846 4.78117 0.743103Z"
                                        fill="#FFF"
                                      ></path>
                                      <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M8.43961 4.23998C8.64623 4.43922 8.65221 4.76823 8.45297 4.97484L5.40604 8.13462L3.54703 6.20676C3.34779 6.00014 3.35377 5.67113 3.56039 5.47189C3.76701 5.27266 4.09602 5.27864 4.29526 5.48525L5.40604 6.63718L7.70475 4.25334C7.90398 4.04672 8.23299 4.04074 8.43961 4.23998Z"
                                        fill="#000"
                                      ></path>
                                    </svg>
                                  </div>
                                ) : null}
                              </div>
                            </a>
                          )}

                          <p style={{ color: 'white' }}>
                            {this.state.namemint === ''
                              ? this.state.minter.substring(0, 8) + '...'
                              : this.state.namemint}{' '}
                            <a href="https://fantums.com" target="_blank">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-arrow-up-right-square-fill"
                                viewBox="0 0 16 16"
                              >
                                <path d="M14 0a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h12zM5.904 10.803 10 6.707v2.768a.5.5 0 0 0 1 0V5.5a.5.5 0 0 0-.5-.5H6.525a.5.5 0 1 0 0 1h2.768l-4.096 4.096a.5.5 0 0 0 .707.707z" />
                              </svg>
                            </a>
                          </p>
                        </ReactPlaceholder>
                        <br />
                      </div>
                    </div>
                  </ReactPlaceholder>
                  {/* <ReactPlaceholder type='rect' ready={this.state.likecount} showLoadingAnimation={true} color='#333' style={{ width: '100%', height: '75px', marginTop: "10px", borderRadius: '15px' }}> 
                            <div className="row" align="center">
                                <div className="col-6" align="center">
                                    <a href="" onClick={(e) => this.like(e, this.state.owner, key)}><i className="fa fa-heart fa-2x like" id={"like"+key}></i></a><br /><span id={"count"+key} style={{fontSize: "19px", color: "white"}}>{this.state.likecount}</span>
                                </div>
                                <div className="col-6" align="center">
                                    <a href="" onClick={(e) => this.ice(e, this.state.minter, key)}><i className="fas fa-gem fa-2x ice" id={"ice"+key}></i></a><br /><span id={"counti"+key} style={{fontSize: "19px", color: "white"}}>{this.state.icecount}</span>
                                </div>
                            </div>   
                            </ReactPlaceholder>                          */}
                  <br />
                  <div className="table-adj">
                    <div className="table-responsive">
                      <ReactPlaceholder
                        type="rect"
                        ready={this.state.ready}
                        showLoadingAnimation={true}
                        color="#333"
                        style={{
                          width: '100%',
                          height: '400px',
                          borderRadius: '15px',
                        }}
                        align="center"
                      >
                        <table className="table table-sm table-borderless">
                          <tbody className="">
                            <tr>
                              <th className="pl-0 w-40" scope="row">
                                <strong>Hash</strong>
                              </th>

                              <td style={{ fontSize: '14px' }}>
                                <ReactPlaceholder
                                  type="rect"
                                  ready={this.state.imageData_nftData}
                                  showLoadingAnimation={true}
                                  color="#333"
                                  style={{
                                    width: '150px',
                                    height: '24px',
                                    marginTop: '10px',
                                    borderRadius: '15px',
                                  }}
                                >
                                  {' '}
                                  {this.state.imageData_nftData}{' '}
                                  <a
                                    target="_blank"
                                    href={'https://fantums.com/token/' + key}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="16"
                                      height="16"
                                      fill="currentColor"
                                      className="bi bi-arrow-up-right-square-fill"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M14 0a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h12zM5.904 10.803 10 6.707v2.768a.5.5 0 0 0 1 0V5.5a.5.5 0 0 0-.5-.5H6.525a.5.5 0 1 0 0 1h2.768l-4.096 4.096a.5.5 0 0 0 .707.707z" />
                                    </svg>
                                  </a>
                                </ReactPlaceholder>
                              </td>
                            </tr>
                            <tr>
                              <th className="pl-0 w-60" scope="row">
                                <strong>Category</strong>
                              </th>
                              <td>Fantums</td>
                            </tr>
                            <tr>
                              <th className="pl-0 w-60" scope="row">
                                <strong>Level</strong>
                              </th>
                              <td>{this.state.imageData_level}</td>
                            </tr>
                            <tr>
                              <th className="pl-0 w-60" scope="row">
                                <strong>Rank</strong>
                              </th>
                              <td>{this.state.imageData_rank}</td>
                            </tr>
                            <tr>
                              <th className="pl-0 w-60" scope="row">
                                <strong>Rarity</strong>
                              </th>
                              <td>
                                {this.state.imageData_rarity}{' '}
                                {this.state.imageData_rarityname}
                              </td>
                            </tr>
                            {/* <tr>
                                                <th className="pl-0 w-60" scope="row"><strong>Status</strong></th>
                                                <td>{(this.state.blacklisted) ? 'Blacklisted' : 'Listed'}&nbsp;&nbsp;&nbsp;<a href="https://forms.gle/x5UpmLcjpqyeKrAf8" target="_blank" className="button btn btn-primary">Report This NFT!</a></td>
                                            </tr> */}
                            <tr>
                              <th className="pl-0 w-60" scope="row">
                                <strong>URL</strong>
                              </th>
                              <td>{this.state.imageData_url}</td>
                            </tr>

                            <tr>
                              <th className="pl-0 w-60" scope="row">
                                <strong>For Sale</strong>
                              </th>
                              <td>
                                <ReactPlaceholder
                                  type="rect"
                                  ready={this.state.loaded}
                                  showLoadingAnimation={true}
                                  color="#333"
                                  style={{
                                    width: '150px',
                                    height: '24px',
                                    marginTop: '10px',
                                    borderRadius: '15px',
                                  }}
                                >
                                  {this.state.loaded === true
                                    ? this.state.approved
                                      ? 'Available for Purchase'
                                      : 'Not Listed For Sale'
                                    : null}
                                </ReactPlaceholder>
                              </td>
                            </tr>
                            <tr>
                              <th className="pl-0 w-60" scope="row">
                                <strong>FUM NFT ID</strong>
                              </th>
                              <td>
                                {key} of {this.state.totalSupply}
                                &nbsp;&nbsp;&nbsp;
                                <a
                                  href={this.state.imageData_image}
                                  target="_blank"
                                  className="button btn btn-primary"
                                >
                                  View Full NFT!
                                </a>
                                &nbsp;&nbsp;&nbsp;
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        {/* // TODO:GANTII */}
                        <div align="center">
                          <TwitterTweetButton
                            url={'https://serabe.xyz/fum/' + key}
                            user="serabe"
                            align="center"
                          />
                        </div>
                      </ReactPlaceholder>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-4" align="center">
              {this.state.transactionl > 0 ? (
                <ReactPlaceholder
                  type="rect"
                  ready={this.state.ready}
                  showLoadingAnimation={true}
                  color="#333"
                  style={{
                    width: '100%',
                    height: '200px',
                    borderRadius: '15px',
                  }}
                  align="center"
                >
                  <h4 className="text-secondary">Transactions</h4>
                  <table
                    className="table table-sm table-borderless"
                    align="center"
                  >
                    <thead align="center">
                      <tr align="center">
                        <th align="center">Buyer</th>
                        <th align="center">Price in SEBI</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.transactions
                        .slice(0)
                        .reverse()
                        .map((transaction, i) => {
                          return transaction._tokenId === key ? (
                            <tr key={i} align="center">
                              <td align="center">
                                {transaction._buyer}{' '}
                                <a href={'/collection/' + transaction._buyer}>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-arrow-up-right-square-fill"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M14 0a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h12zM5.904 10.803 10 6.707v2.768a.5.5 0 0 0 1 0V5.5a.5.5 0 0 0-.5-.5H6.525a.5.5 0 1 0 0 1h2.768l-4.096 4.096a.5.5 0 0 0 .707.707z" />
                                  </svg>
                                </a>
                              </td>
                              <td align="center">{transaction._price}</td>
                            </tr>
                          ) : null
                        })}
                    </tbody>
                  </table>
                </ReactPlaceholder>
              ) : null}
            </div>
          </div>
        </LoadingOverlay>
      </div>
    )
  }

  constructor(props) {
    super(props)
    this.state = {
      account: '',
      contract: null,
      loaded: false,
      sale_contract: null,
      token_contract: null,
      contractserabe: null,
      totalSupply: 0,
      token_price: 0,
      images: [],
      owner: null,
      estftm: 0,
      blacklisted: false,
      imageData_name: [],
      imageData_nftData: [],
      imageData_mimeType: null,
      imageData_category: [],
      imageData_price: [],
      imageData_des: [],
      imageData_url: [],
      imageData_image: [],
      imageData_usdprice: [],
      imageData_ftmprice: [],
      imageData_slippage: [],
      imageData_rarity: [],
      imageData_rarityname: [],
      imageData_rank: [],
      selling_to: '',
      selling_price: null,
      approved: false,
      new_price: null,
      txpend: false,
      readyowner: false,
      readyminter: false,
      txs: 0,
      minted: [],
      minter: '',
      connected: false,
      transactionl: 0,
      transactions: [],
      owner_verified: [],
      mint_verified: [],
      mintedr: [],
      mintedcollection: '',
      ipfs: '',
      mim: '',
      name: '',
      ipfsmint: '',
      mimmint: '',
      namemint: '',
      ipfsb: '',
      mimb: '',
      accttosend: '',
      likecount: 0,
      icecount: 0,
    }
  }

  async componentWillMount() {
    await this.loadBlockchainData()
  }

  async like(e, owner, key) {
    e.preventDefault()
    e.stopPropagation()

    if (!window.loaded_web3) {
      alert('You must connect with Metamask!')
      return
    }

    document.getElementById('like' + key).classList.add('fa-pulse')

    const web3t = window.web3

    const accounts = await window.web3.eth.getAccounts()
    const acct = accounts[0]

    const abilike = SerabeNFTLikes.abi
    const contractlike = new web3t.eth.Contract(
      abilike,
      '0x68a21eCB96E683E85251E1B01ac0FC8df690EE2A',
    )

    contractlike.methods
      .LikeNFT(owner, key)
      .send({ from: acct })
      .once('receipt', receipt => {
        console.log('NFT Liked!')
        document.getElementById('like' + key).classList.remove('fa-pulse')
        document.getElementById('like' + key).classList.add('liked')
        document.getElementById('count' + key).innerHTML =
          Number(document.getElementById('count' + key).innerHTML) + 1

        // this.setState({ txpend: false })
        // this.setState({ txs: 0 })
      })
      .catch(error => {
        // Transaction rejected or failed
        document.getElementById('like' + key).classList.remove('fa-pulse')
        alert('Like failed!')
        console.log(error)
        // this.setState({ txpend: false });
      })
  }

  async ice(e, owner, key) {
    e.preventDefault()
    e.stopPropagation()

    if (!window.loaded_web3) {
      alert('You must connect with Metamask!')
      return
    }

    document.getElementById('ice' + key).classList.add('fa-pulse')

    const web3t = window.web3

    const testnetHarmonyRpc = 'https://api.s0.b.hmny.io'
    const web3ftm = new Web3(testnetHarmonyRpc)

    const accounts = await window.web3.eth.getAccounts()
    const acct = accounts[0]

    const networkData = SerabeNFT.networks[1666700000]
    const abi = SerabeNFT.abi
    const address = networkData.address
    const contract = new web3ftm.eth.Contract(abi, address)

    // Get minter of NFT
    const mintedr = await contract.getPastEvents('Transfer', {
      fromBlock: 0,
      toBlock: 'latest',
    })

    for (var i = 0; i < mintedr.length; i++) {
      this.setState({
        mintedr: [...this.state.mintedr, mintedr[i].returnValues],
      })
    }

    // console.log(this.state.minted)

    for (i = 0; i < this.state.mintedr.length; i++) {
      // console.log(this.state.transactions[i]._buyer)
      // console.log(this.state.minted[i].tokenId)
      if (this.state.mintedr[i].tokenId == key) {
        // console.log('hoorah!');
        if (
          this.state.mintedr[i].from ==
          '0x0000000000000000000000000000000000000000'
        ) {
          // console.log('hoorah TWICE!')
          // console.log(this.state.minted[i].to);
          this.setState({ mintedcollection: this.state.mintedr[i].to })
        }
      }
      // if (key === this.state.minted[i].tokenId && this.state.minted[i].from === "0x0000000000000000000000000000000000000000") {
      //     console.log(this.state.minted[i].to)
      //     console.log('found key')
      //     this.setState({ mintedcollection: this.state.minted[i].to });
      // } else {
      //     console.log('no go');
      //     // Nothing atm
      // }
    }

    // console.log(this.state.mintedcollection)

    const abilike = SerabeNFTLikes.abi
    const contractlike = new web3t.eth.Contract(
      abilike,
      '0x68a21eCB96E683E85251E1B01ac0FC8df690EE2A',
    )

    const abib = Serabe.abi
    const addressb = '0x07625EFb8E31D7419427dDa993Ce68a41c04a5de' //Serabe Token
    const token_contract = new web3t.eth.Contract(abib, addressb)

    const iceprice = '500'

    token_contract.methods
      .approve(
        '0x68a21eCB96E683E85251E1B01ac0FC8df690EE2A',
        web3ftm.utils.toWei(iceprice, 'ether'),
      )
      .send({ from: acct })
      .once('receipt', receipt => {
        contractlike.methods
          .IceNFT(this.state.mintedcollection, key)
          .send({ from: acct })
          .once('receipt', receipt => {
            console.log('NFT Iced!')
            document.getElementById('ice' + key).classList.remove('fa-pulse')
            document.getElementById('ice' + key).classList.add('iced')
            document.getElementById('counti' + key).innerHTML =
              Number(document.getElementById('counti' + key).innerHTML) + 5

            // this.setState({ txpend: false })
            // this.setState({ txs: 0 })
          })
          .catch(error => {
            // Transaction rejected or failed
            document.getElementById('ice' + key).classList.remove('fa-pulse')
            alert('Ice failed!')
            console.log(error)
            // this.setState({ txpend: false });
          })
      })
      .catch(error => {
        // Transaction rejected or failed
        document.getElementById('ice' + key).classList.remove('fa-pulse')
        alert('Ice failed!')
        console.log(error)
        // this.setState({ txpend: false });
      })
  }

  async loadBlockchainData() {
    const web3 = window.web3

    const testnetHarmonyRpc = 'https://api.s0.b.hmny.io'
    const web3t = new Web3(testnetHarmonyRpc)

    // Load account
    if (typeof web3 !== 'undefined') {
      const accounts = await web3.eth.getAccounts()
      this.setState({ account: accounts[0] })
      this.setState({ connected: true })
      const chainId = await web3.eth.getChainId()
      if (chainId === 1666700000) {
        this.setState({ connected: true })
        console.log(this.state.connected)
        console.log(this.state.account)
      } else {
        this.setState({ connected: false })
      }
    }

    // console.log(web3)

    const networkId = 1666700000
    const networkData = SerabeNFT.networks[networkId]

    const abi = SerabeNFT.abi
    const address = networkData.address

    if (web3 !== undefined) {
      const contract = new web3.eth.Contract(abi, address)
      console.log(contract)
      this.setState({ contract })
    }

    const abiaa = SerabeNFT.abi
    const addressaa = networkData.address
    const maincontract = new web3t.eth.Contract(abiaa, addressaa)
    // console.log(maincontract)
    this.setState({ maincontract })

    const sale_networkData = SerabeNFTSale.networks[networkId]
    const sale_abi = SerabeNFTSale.abi
    const sale_address = sale_networkData.address
    if (web3 !== undefined) {
      const sale_contract = new web3.eth.Contract(sale_abi, sale_address)
      this.setState({ sale_contract })
    }

    const sale_networkData2 = SerabeNFTSale.networks[networkId]
    const sale_abi2 = SerabeNFTSale.abi
    const sale_address2 = sale_networkData2.address
    const sale_contract2 = new web3t.eth.Contract(sale_abi2, sale_address2)
    this.setState({ sale_contract2 })

    const abib = Serabe.abi
    const addressb = '0x07625EFb8E31D7419427dDa993Ce68a41c04a5de' //Serabe Token
    if (web3 !== undefined) {
      const token_contract = new web3.eth.Contract(abib, addressb)
      this.setState({ token_contract })
    }

    const newabi = SerabeAvatars.abi
    const addressa = '0xeB973420cb687A2301B0daE9ae3064DdA9474382' //SerabeAvatars
    const contractav = new web3t.eth.Contract(newabi, addressa)
    // console.log(contract)
    this.setState({ contractav })

    const abiv = SerabeVerified.abi
    const addv = '0x44434382a732494325202c3607c267835aF08b13' // Serabeverified
    const contractv = new web3t.eth.Contract(abiv, addv)
    // console.log(contract)
    this.setState({ contractv })

    const abip = [
      { inputs: [], stateMutability: 'nonpayable', type: 'constructor' },
      {
        inputs: [],
        name: 'getLatestFTMPrice',
        outputs: [{ internalType: 'int256', name: '', type: 'int256' }],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [
          { internalType: 'address', name: 'pairAddress', type: 'address' },
          { internalType: 'uint256', name: 'amount', type: 'uint256' },
        ],
        name: 'getLatestTokenPrice',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function',
      },
    ]
    const addp = '0xF6377852a6Aa9487f8423336d334fF2f24ED497A' // SerabePriceOracle 0xF6377852a6Aa9487f8423336d334fF2f24ED497A
    const contractp = new web3t.eth.Contract(abip, addp)
    this.setState({ contractp })

    const ftmprice = (await contractp.methods.getLatestFTMPrice().call()) / 1e8
    const sebiperftm = await contractp.methods
      .getLatestTokenPrice('0x1ecdab40fe2424a511fd35250ddc37a0ae7c1bb9', 1)
      .call() //Pair SEBI - ONE
    const sebiusd = ftmprice / (sebiperftm / 1e18)

    const abilike = SerabeNFTLikes.abi
    const contractlike = new web3t.eth.Contract(
      abilike,
      '0x68a21eCB96E683E85251E1B01ac0FC8df690EE2A',
    ) //SerabeNFTLikes

    this.setState({ contractlike })

    const abiblack = [
      { inputs: [], stateMutability: 'nonpayable', type: 'constructor' },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: 'address',
            name: 'hashAddress',
            type: 'address',
          },
          {
            indexed: false,
            internalType: 'bool',
            name: 'blacklisted',
            type: 'bool',
          },
        ],
        name: 'SetBlackListedAddress',
        type: 'event',
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: 'uint256',
            name: 'nftID',
            type: 'uint256',
          },
          {
            indexed: false,
            internalType: 'bool',
            name: 'blacklisted',
            type: 'bool',
          },
        ],
        name: 'SetBlackListedNFT',
        type: 'event',
      },
      {
        inputs: [],
        name: 'AddyCount',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [],
        name: 'IDCount',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [
          { internalType: 'address', name: 'blAddress', type: 'address' },
        ],
        name: 'getBlackListedAddress',
        outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [{ internalType: 'uint256', name: 'nftID', type: 'uint256' }],
        name: 'getBlackListedNFT',
        outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        name: 'idupdates',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [],
        name: 'owner',
        outputs: [{ internalType: 'address', name: '', type: 'address' }],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [
          { internalType: 'address', name: 'addy', type: 'address' },
          { internalType: 'bool', name: 'blacklisted', type: 'bool' },
        ],
        name: 'setBlackListedAddress',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        inputs: [
          { internalType: 'uint256', name: 'nftID', type: 'uint256' },
          { internalType: 'bool', name: 'blacklisted', type: 'bool' },
        ],
        name: 'setBlackListedNFT',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        name: 'updates',
        outputs: [{ internalType: 'address', name: '', type: 'address' }],
        stateMutability: 'view',
        type: 'function',
      },
    ]
    const contractblack = new web3t.eth.Contract(
      abiblack,
      '0x2f2B7ae467E4f36B0F8f481481E58bFd1a4ef658',
    )

    this.setState({ contractblack })

    const serabeabi = [
      { inputs: [], stateMutability: 'nonpayable', type: 'constructor' },
      {
        inputs: [
          { internalType: 'uint256', name: 'sebiAmount', type: 'uint256' },
        ],
        name: 'getEstimatedFTMforSEBI',
        outputs: [{ internalType: 'uint256[]', name: '', type: 'uint256[]' }],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [
          { internalType: 'uint256', name: 'ftmAmount', type: 'uint256' },
        ],
        name: 'getEstimatedSEBIforFTM',
        outputs: [{ internalType: 'uint256[]', name: '', type: 'uint256[]' }],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [
          { internalType: 'uint256', name: 'sebiAmount', type: 'uint256' },
          { internalType: 'uint256', name: 'deadline', type: 'uint256' },
        ],
        name: 'serabe',
        outputs: [],
        stateMutability: 'payable',
        type: 'function',
      },
      {
        inputs: [],
        name: 'uniswapRouter',
        outputs: [
          {
            internalType: 'contract IUniswapV2Router02',
            name: '',
            type: 'address',
          },
        ],
        stateMutability: 'view',
        type: 'function',
      },
      { stateMutability: 'payable', type: 'receive' },
    ]

    if (web3 !== undefined) {
      const contractserabe = new web3.eth.Contract(
        serabeabi,
        '0x23AEbfe8D5a5D3Af10E5643794aE28962575A64E',
      ) //Serabe Viper
      this.setState({ contractserabe })
    }

    const abigantom = [
      { inputs: [], stateMutability: 'nonpayable', type: 'constructor' },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: 'address',
            name: 'owner',
            type: 'address',
          },
          {
            indexed: true,
            internalType: 'address',
            name: 'approved',
            type: 'address',
          },
          {
            indexed: true,
            internalType: 'uint256',
            name: 'tokenId',
            type: 'uint256',
          },
        ],
        name: 'Approval',
        type: 'event',
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: 'address',
            name: 'owner',
            type: 'address',
          },
          {
            indexed: true,
            internalType: 'address',
            name: 'operator',
            type: 'address',
          },
          {
            indexed: false,
            internalType: 'bool',
            name: 'approved',
            type: 'bool',
          },
        ],
        name: 'ApprovalForAll',
        type: 'event',
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: 'address',
            name: 'previousOwner',
            type: 'address',
          },
          {
            indexed: true,
            internalType: 'address',
            name: 'newOwner',
            type: 'address',
          },
        ],
        name: 'OwnershipTransferred',
        type: 'event',
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: false,
            internalType: 'address',
            name: 'account',
            type: 'address',
          },
        ],
        name: 'Paused',
        type: 'event',
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: 'address',
            name: 'from',
            type: 'address',
          },
          {
            indexed: true,
            internalType: 'address',
            name: 'to',
            type: 'address',
          },
          {
            indexed: true,
            internalType: 'uint256',
            name: 'tokenId',
            type: 'uint256',
          },
        ],
        name: 'Transfer',
        type: 'event',
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: false,
            internalType: 'address',
            name: 'account',
            type: 'address',
          },
        ],
        name: 'Unpaused',
        type: 'event',
      },
      {
        inputs: [
          { internalType: 'address', name: 'to', type: 'address' },
          { internalType: 'uint256', name: 'tokenId', type: 'uint256' },
        ],
        name: 'approve',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        inputs: [{ internalType: 'address', name: 'owner', type: 'address' }],
        name: 'balanceOf',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [{ internalType: 'uint256', name: 'tokenId', type: 'uint256' }],
        name: 'burn',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        inputs: [{ internalType: 'string', name: 'uri', type: 'string' }],
        name: 'buyMint',
        outputs: [],
        stateMutability: 'payable',
        type: 'function',
      },
      {
        inputs: [{ internalType: 'uint256', name: 'tokenId', type: 'uint256' }],
        name: 'getApproved',
        outputs: [{ internalType: 'address', name: '', type: 'address' }],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [],
        name: 'getPrice',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [
          { internalType: 'address', name: 'owner', type: 'address' },
          { internalType: 'address', name: 'operator', type: 'address' },
        ],
        name: 'isApprovedForAll',
        outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [],
        name: 'name',
        outputs: [{ internalType: 'string', name: '', type: 'string' }],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [],
        name: 'owner',
        outputs: [{ internalType: 'address', name: '', type: 'address' }],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [{ internalType: 'uint256', name: 'tokenId', type: 'uint256' }],
        name: 'ownerOf',
        outputs: [{ internalType: 'address', name: '', type: 'address' }],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [],
        name: 'pause',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        inputs: [],
        name: 'paused',
        outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [],
        name: 'payout',
        outputs: [],
        stateMutability: 'payable',
        type: 'function',
      },
      {
        inputs: [],
        name: 'renounceOwnership',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        inputs: [
          { internalType: 'address', name: 'to', type: 'address' },
          { internalType: 'string', name: 'uri', type: 'string' },
        ],
        name: 'safeMint',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        inputs: [
          { internalType: 'address', name: 'from', type: 'address' },
          { internalType: 'address', name: 'to', type: 'address' },
          { internalType: 'uint256', name: 'tokenId', type: 'uint256' },
        ],
        name: 'safeTransferFrom',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        inputs: [
          { internalType: 'address', name: 'from', type: 'address' },
          { internalType: 'address', name: 'to', type: 'address' },
          { internalType: 'uint256', name: 'tokenId', type: 'uint256' },
          { internalType: 'bytes', name: '_data', type: 'bytes' },
        ],
        name: 'safeTransferFrom',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        inputs: [
          { internalType: 'address', name: 'operator', type: 'address' },
          { internalType: 'bool', name: 'approved', type: 'bool' },
        ],
        name: 'setApprovalForAll',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        inputs: [
          { internalType: 'bytes4', name: 'interfaceId', type: 'bytes4' },
        ],
        name: 'supportsInterface',
        outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [],
        name: 'symbol',
        outputs: [{ internalType: 'string', name: '', type: 'string' }],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [{ internalType: 'uint256', name: 'index', type: 'uint256' }],
        name: 'tokenByIndex',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [
          { internalType: 'address', name: 'owner', type: 'address' },
          { internalType: 'uint256', name: 'index', type: 'uint256' },
        ],
        name: 'tokenOfOwnerByIndex',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [{ internalType: 'uint256', name: 'tokenId', type: 'uint256' }],
        name: 'tokenURI',
        outputs: [{ internalType: 'string', name: '', type: 'string' }],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [],
        name: 'totalSupply',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [
          { internalType: 'address', name: 'from', type: 'address' },
          { internalType: 'address', name: 'to', type: 'address' },
          { internalType: 'uint256', name: 'tokenId', type: 'uint256' },
        ],
        name: 'transferFrom',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        inputs: [
          { internalType: 'address', name: 'newOwner', type: 'address' },
        ],
        name: 'transferOwnership',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        inputs: [],
        name: 'unpause',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
      },
    ]
    const contractgantom = new web3t.eth.Contract(
      abigantom,
      '0x16478F0d02b5452ADc688ac2b33744451b9771e9',
    ) // Harmony Digital

    const abifantums = [
      { inputs: [], stateMutability: 'nonpayable', type: 'constructor' },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: 'address',
            name: 'owner',
            type: 'address',
          },
          {
            indexed: true,
            internalType: 'address',
            name: 'approved',
            type: 'address',
          },
          {
            indexed: true,
            internalType: 'uint256',
            name: 'tokenId',
            type: 'uint256',
          },
        ],
        name: 'Approval',
        type: 'event',
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: 'address',
            name: 'owner',
            type: 'address',
          },
          {
            indexed: true,
            internalType: 'address',
            name: 'operator',
            type: 'address',
          },
          {
            indexed: false,
            internalType: 'bool',
            name: 'approved',
            type: 'bool',
          },
        ],
        name: 'ApprovalForAll',
        type: 'event',
      },
      {
        inputs: [
          { internalType: 'address', name: 'to', type: 'address' },
          { internalType: 'uint256', name: 'tokenId', type: 'uint256' },
        ],
        name: 'approve',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: false,
            internalType: 'uint256',
            name: 'tokenId',
            type: 'uint256',
          },
        ],
        name: 'MintedFantum',
        type: 'event',
      },
      {
        inputs: [
          { internalType: 'string', name: 'nonce', type: 'string' },
          { internalType: 'string', name: 'randomName', type: 'string' },
        ],
        name: 'mintFantum',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'payable',
        type: 'function',
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: false,
            internalType: 'uint256',
            name: 'tokenId',
            type: 'uint256',
          },
          {
            indexed: false,
            internalType: 'string',
            name: 'newName',
            type: 'string',
          },
        ],
        name: 'NamedFantum',
        type: 'event',
      },
      {
        inputs: [
          { internalType: 'uint256', name: 'tokenId', type: 'uint256' },
          { internalType: 'string', name: 'name', type: 'string' },
        ],
        name: 'nameFantum',
        outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
        stateMutability: 'payable',
        type: 'function',
      },
      {
        inputs: [
          { internalType: 'address', name: 'from', type: 'address' },
          { internalType: 'address', name: 'to', type: 'address' },
          { internalType: 'uint256', name: 'tokenId', type: 'uint256' },
        ],
        name: 'safeTransferFrom',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        inputs: [
          { internalType: 'address', name: 'from', type: 'address' },
          { internalType: 'address', name: 'to', type: 'address' },
          { internalType: 'uint256', name: 'tokenId', type: 'uint256' },
          { internalType: 'bytes', name: '_data', type: 'bytes' },
        ],
        name: 'safeTransferFrom',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        inputs: [
          { internalType: 'address', name: 'operator', type: 'address' },
          { internalType: 'bool', name: 'approved', type: 'bool' },
        ],
        name: 'setApprovalForAll',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        inputs: [
          { internalType: 'uint8', name: 'difficultyBytes', type: 'uint8' },
        ],
        name: 'setDifficultyBytes',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        inputs: [
          { internalType: 'address[]', name: 'owners', type: 'address[]' },
        ],
        name: 'setOwners',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: 'address',
            name: 'from',
            type: 'address',
          },
          {
            indexed: true,
            internalType: 'address',
            name: 'to',
            type: 'address',
          },
          {
            indexed: true,
            internalType: 'uint256',
            name: 'tokenId',
            type: 'uint256',
          },
        ],
        name: 'Transfer',
        type: 'event',
      },
      {
        inputs: [
          { internalType: 'address', name: 'from', type: 'address' },
          { internalType: 'address', name: 'to', type: 'address' },
          { internalType: 'uint256', name: 'tokenId', type: 'uint256' },
        ],
        name: 'transferFrom',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        inputs: [
          { internalType: 'uint256', name: 'amount', type: 'uint256' },
          { internalType: 'address', name: 'tokenAddress', type: 'address' },
        ],
        name: 'withdrawOtherToken',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        inputs: [],
        name: '_burn',
        outputs: [{ internalType: 'address', name: '', type: 'address' }],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [],
        name: '_difficultyBytes',
        outputs: [{ internalType: 'uint8', name: '', type: 'uint8' }],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [],
        name: '_foo',
        outputs: [{ internalType: 'address', name: '', type: 'address' }],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        name: '_owners',
        outputs: [{ internalType: 'address', name: '', type: 'address' }],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [],
        name: '_sushi',
        outputs: [{ internalType: 'address', name: '', type: 'address' }],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [],
        name: '_tokenIds',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [],
        name: '_wftm',
        outputs: [{ internalType: 'address', name: '', type: 'address' }],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [{ internalType: 'address', name: 'owner', type: 'address' }],
        name: 'balanceOf',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [],
        name: 'baseURI',
        outputs: [{ internalType: 'string', name: '', type: 'string' }],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [{ internalType: 'uint256', name: 'tokenId', type: 'uint256' }],
        name: 'getApproved',
        outputs: [{ internalType: 'address', name: '', type: 'address' }],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [],
        name: 'getDifficultyBytes',
        outputs: [
          { internalType: 'uint8', name: 'difficultyBytes', type: 'uint8' },
        ],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [],
        name: 'getOwners',
        outputs: [
          { internalType: 'address[]', name: 'addresses', type: 'address[]' },
        ],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [{ internalType: 'uint256', name: 'tokenId', type: 'uint256' }],
        name: 'hashOf',
        outputs: [{ internalType: 'bytes', name: '', type: 'bytes' }],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [
          { internalType: 'address', name: 'owner', type: 'address' },
          { internalType: 'address', name: 'operator', type: 'address' },
        ],
        name: 'isApprovedForAll',
        outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [],
        name: 'isOwner',
        outputs: [{ internalType: 'bool', name: 'anOwner', type: 'bool' }],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [],
        name: 'name',
        outputs: [{ internalType: 'string', name: '', type: 'string' }],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [{ internalType: 'uint256', name: 'tokenId', type: 'uint256' }],
        name: 'nameChangedTimesOf',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [{ internalType: 'uint256', name: 'count', type: 'uint256' }],
        name: 'nameChangePriceOf',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [{ internalType: 'uint256', name: 'tokenId', type: 'uint256' }],
        name: 'nameOf',
        outputs: [{ internalType: 'string', name: '', type: 'string' }],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [{ internalType: 'uint256', name: 'tokenId', type: 'uint256' }],
        name: 'ownerOf',
        outputs: [{ internalType: 'address', name: '', type: 'address' }],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [{ internalType: 'uint256', name: 'tokenId', type: 'uint256' }],
        name: 'priceOf',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'pure',
        type: 'function',
      },
      {
        inputs: [
          { internalType: 'bytes4', name: 'interfaceId', type: 'bytes4' },
        ],
        name: 'supportsInterface',
        outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [],
        name: 'symbol',
        outputs: [{ internalType: 'string', name: '', type: 'string' }],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [{ internalType: 'uint256', name: 'index', type: 'uint256' }],
        name: 'tokenByIndex',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [{ internalType: 'bytes32', name: 'hash', type: 'bytes32' }],
        name: 'tokenIdForHash',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [
          { internalType: 'address', name: 'owner', type: 'address' },
          { internalType: 'uint256', name: 'index', type: 'uint256' },
        ],
        name: 'tokenOfOwnerByIndex',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [{ internalType: 'uint256', name: 'tokenId', type: 'uint256' }],
        name: 'tokenURI',
        outputs: [{ internalType: 'string', name: '', type: 'string' }],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [],
        name: 'totalFantums',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [],
        name: 'totalSupply',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function',
      },
    ]
    const contractfantums = new web3t.eth.Contract(
      abifantums,
      '0x98c70a6d1aBEdA84A13EE2b09Fb7953CF4F4621f',
    ) //FumCollectible

    // console.log(await contract.getPastEvents('Transfer', { fromBlock: 0, toBlock: 'latest' }))

    // const nft_id_path = window.location.hash.split('/')
    const nft_id_path = window.location.href.split('/')
    const key = nft_id_path[nft_id_path.length - 1]

    const totalSupply = await contractfantums.methods.totalFantums().call()
    // console.log(totalSupply)
    this.setState({ totalSupply })

    // Load Owner
    const owner = await contractfantums.methods.ownerOf(key).call()

    this.setState({ owner })

    // Load NFTs Data

    var getJSON = function(url, callback) {
      var xhr = new XMLHttpRequest()
      xhr.open('GET', url, true)
      xhr.responseType = 'json'
      xhr.onload = function() {
        var status = xhr.status
        if (status === 200) {
          callback(null, xhr.response)
        } else {
          callback(status, xhr.response)
        }
      }
      xhr.send()
    }

    const metadata = await contractfantums.methods.tokenURI(key).call()
    console.log(metadata)

    this.setState({ imageData_url: metadata })

    fetch(metadata, { mode: 'cors' })
      .then(response => {
        console.log(response)
        return response.json()
      })
      .then(data => {
        console.log(data)
        this.setState({ imageData_name: data.name })
        this.setState({ imageData_image: data.image })

        this.setState({ imageData_nftData: data.hash })
        this.setState({ imageData_price: data.price })
        this.setState({ imageData_description: data.description })
        this.setState({ imageData_rarity: data.rScore })
        this.setState({ imageData_rarityname: data.rarityName })
        this.setState({ imageData_rank: data.rarity })
        this.setState({ imageData_dead: data.dead })
        this.setState({ imageData_owner: data.owner })
        this.setState({ imageData_id: data.id })
        this.setState({ imageData_level: data.level })
        this.setState({ ready: true })

        this.setState({ loaded: true })
        this.setState({ ready: true })

        window.prerenderReady = true
      })
      .catch(err => {
        console.log(err)
      })

    this.setState({
      images: [...this.state.images, metadata.name],
    })



    const token_ID = key

    // console.log(sale_contract)

    const getIPFS = await contractav.methods
      .getIPFSHash(this.state.owner)
      .call()
    // console.log(getIPFS)
    const getMIME = await contractav.methods
      .getMIMEType(this.state.owner)
      .call()
    // console.log(getMIME)
    const getName = await contractav.methods.getName(this.state.owner).call()
    this.setState({ ipfs: getIPFS })
    this.setState({ mim: getMIME })
    this.setState({ name: getName })

    const getIPFSm =
      'bafkreicfpc24tgug6nmmmbj4tf6qmopwzj2ezzosxfzdhwt654jrf22qza'
    // console.log(getIPFSm)
    const getMIMEm = 'image/png'
    // console.log(getMIMEm)
    const getNamem = 'Fantums'
    // console.log(getNamem)
    this.setState({ ipfsmint: getIPFSm })
    this.setState({ mimmint: getMIMEm })
    this.setState({ namemint: getNamem })

    const getOwnerVerified = await contractv.methods
      .getVerified(this.state.owner)
      .call()
    const getMintVerified = true

    this.setState({ owner_verified: getOwnerVerified })
    this.setState({ mint_verified: getMintVerified })
    this.setState({ readyowner: true })
    this.setState({ readyminter: true })
  }

  buySebi = key => {
    const web3 = window.web3
    this.setState({ txpend: true })
    this.setState({ txs: 2 })

    this.state.token_contract.methods
      .approve(
        '0x3B1644C3942aEb8dD3B8c7AdDfE8b0d38D7d7Bee',
        web3.utils.toWei(this.state.imageData_price, 'ether'),
      )
      .send({ from: this.state.account })
      .once('receipt', receipt => {
        this.setState({ txpend: true })
        this.setState({ txs: 1 })

        this.state.sale_contract.methods
          .BuyNFT(this.state.owner, key, this.state.imageData_price)
          .send({ from: this.state.account })
          .once('receipt', receipt => {
            console.log('NFT Bought!')
            document.getElementById('buyanNFT').innerHTML =
              "<span style='color: #00ff5a !important;font-weight:bold;'>NFT Purchased, Go to your collection!</span>"
            this.setState({ txpend: false })
            this.setState({ txs: 0 })
          })
          .catch(error => {
            // Transaction rejected or failed

            alert('Transaction failed on second tx!')
            this.setState({ txpend: false })
          })
      })
      .catch(error => {
        // Transaction rejected or failed

        alert('Transaction failed!')
        this.setState({ txpend: false })
      })
  }

  buySerabe = key => {
    const web3 = window.web3
    this.setState({ txpend: true })
    this.setState({ txs: 4 })

    const ftmabi = [
      {
        inputs: [
          {
            internalType: 'address',
            name: 'src',
            type: 'address',
            indexed: true,
          },
          {
            internalType: 'address',
            name: 'guy',
            indexed: true,
            type: 'address',
          },
          {
            indexed: false,
            name: 'wad',
            type: 'uint256',
            internalType: 'uint256',
          },
        ],
        anonymous: false,
        type: 'event',
        name: 'Approval',
      },
      {
        type: 'event',
        inputs: [
          {
            name: 'dst',
            internalType: 'address',
            type: 'address',
            indexed: true,
          },
          {
            type: 'uint256',
            name: 'wad',
            internalType: 'uint256',
            indexed: false,
          },
        ],
        name: 'Deposit',
        anonymous: false,
      },
      {
        anonymous: false,
        type: 'event',
        name: 'Transfer',
        inputs: [
          {
            name: 'src',
            indexed: true,
            type: 'address',
            internalType: 'address',
          },
          {
            internalType: 'address',
            indexed: true,
            type: 'address',
            name: 'dst',
          },
          {
            type: 'uint256',
            indexed: false,
            name: 'wad',
            internalType: 'uint256',
          },
        ],
      },
      {
        name: 'Withdrawal',
        inputs: [
          {
            type: 'address',
            indexed: true,
            internalType: 'address',
            name: 'src',
          },
          {
            internalType: 'uint256',
            name: 'wad',
            type: 'uint256',
            indexed: false,
          },
        ],
        anonymous: false,
        type: 'event',
      },
      {
        name: 'allowance',
        inputs: [
          { type: 'address', name: '', internalType: 'address' },
          { type: 'address', name: '', internalType: 'address' },
        ],
        type: 'function',
        outputs: [{ internalType: 'uint256', type: 'uint256', name: '' }],
        stateMutability: 'view',
      },
      {
        outputs: [{ type: 'uint256', internalType: 'uint256', name: '' }],
        name: 'balanceOf',
        stateMutability: 'view',
        inputs: [{ name: '', type: 'address', internalType: 'address' }],
        type: 'function',
      },
      {
        outputs: [{ name: '', type: 'uint8', internalType: 'uint8' }],
        stateMutability: 'view',
        inputs: [],
        name: 'decimals',
        type: 'function',
      },
      {
        inputs: [],
        outputs: [{ name: '', type: 'string', internalType: 'string' }],
        name: 'name',
        type: 'function',
        stateMutability: 'view',
      },
      {
        stateMutability: 'view',
        inputs: [],
        outputs: [{ name: '', type: 'string', internalType: 'string' }],
        name: 'symbol',
        type: 'function',
      },
      { stateMutability: 'payable', type: 'receive' },
      {
        name: 'deposit',
        outputs: [],
        stateMutability: 'payable',
        type: 'function',
        inputs: [],
      },
      {
        inputs: [{ name: 'wad', type: 'uint256', internalType: 'uint256' }],
        stateMutability: 'nonpayable',
        type: 'function',
        name: 'withdraw',
        outputs: [],
      },
      {
        outputs: [{ type: 'uint256', internalType: 'uint256', name: '' }],
        inputs: [],
        stateMutability: 'view',
        type: 'function',
        name: 'totalSupply',
      },
      {
        type: 'function',
        outputs: [{ name: '', type: 'bool', internalType: 'bool' }],
        name: 'approve',
        stateMutability: 'nonpayable',
        inputs: [
          { name: 'guy', internalType: 'address', type: 'address' },
          { name: 'wad', type: 'uint256', internalType: 'uint256' },
        ],
      },
      {
        inputs: [
          { name: 'dst', type: 'address', internalType: 'address' },
          { name: 'wad', type: 'uint256', internalType: 'uint256' },
        ],
        name: 'transfer',
        stateMutability: 'nonpayable',
        outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
        type: 'function',
      },
      {
        type: 'function',
        name: 'transferFrom',
        inputs: [
          { type: 'address', internalType: 'address', name: 'src' },
          { type: 'address', name: 'dst', internalType: 'address' },
          { name: 'wad', type: 'uint256', internalType: 'uint256' },
        ],
        stateMutability: 'nonpayable',
        outputs: [{ type: 'bool', internalType: 'bool', name: '' }],
      },
    ]

    const serabeabi = [
      { inputs: [], stateMutability: 'nonpayable', type: 'constructor' },
      {
        inputs: [
          { internalType: 'uint256', name: 'sebiAmount', type: 'uint256' },
        ],
        name: 'getEstimatedFTMforSEBI',
        outputs: [{ internalType: 'uint256[]', name: '', type: 'uint256[]' }],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [
          { internalType: 'uint256', name: 'ftmAmount', type: 'uint256' },
        ],
        name: 'getEstimatedSEBIforFTM',
        outputs: [{ internalType: 'uint256[]', name: '', type: 'uint256[]' }],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [
          { internalType: 'uint256', name: 'sebiAmount', type: 'uint256' },
          { internalType: 'uint256', name: 'deadline', type: 'uint256' },
        ],
        name: 'serabe',
        outputs: [],
        stateMutability: 'payable',
        type: 'function',
      },
      {
        inputs: [],
        name: 'uniswapRouter',
        outputs: [
          {
            internalType: 'contract IUniswapV2Router02',
            name: '',
            type: 'address',
          },
        ],
        stateMutability: 'view',
        type: 'function',
      },
      { stateMutability: 'payable', type: 'receive' },
    ]

    const contractserabe = new web3.eth.Contract(
      serabeabi,
      '0x23AEbfe8D5a5D3Af10E5643794aE28962575A64E',
    ) // SerabeViper
    const contractftm = new web3.eth.Contract(
      ftmabi,
      '0x7466d7d0C21Fa05F32F5a0Fa27e12bdC06348Ce2',
    ) // wrapped ONE

    const slippage =
      Number(this.state.imageData_price * 0.11) +
      Number(this.state.imageData_price)
    // console.log(slippage);

    contractserabe.methods
      .getEstimatedFTMforSEBI(web3.utils.toWei(slippage.toString(), 'ether'))
      .call()
      .then(receipt => {
        const estftm = receipt[0]
        console.log(estftm)

        contractftm.methods
          .approve(
            '0x23AEbfe8D5a5D3Af10E5643794aE28962575A64E', //SerabeViper
            estftm,
          )
          .send({ from: this.state.account })
          .once('receipt', receipt => {
            this.setState({ txpend: true })
            this.setState({ txs: 3 })

            // make new deadline one hour from now in epoch unix time
            const deadline = Math.floor(Date.now() / 1000) + 6000
            console.log(deadline)

            contractserabe.methods
              .serabe(web3.utils.toWei(slippage.toString(), 'ether'), deadline)
              .send({ from: this.state.account, value: estftm })
              .once('receipt', receipt => {
                this.setState({ txpend: true })
                this.setState({ txs: 2 })

                //console.log(receipt);

                console.log('ONE to SEBI successfully!')

                this.state.token_contract.methods
                  .approve(
                    '0x3B1644C3942aEb8dD3B8c7AdDfE8b0d38D7d7Bee',
                    web3.utils.toWei(this.state.imageData_price, 'ether'),
                  )
                  .send({ from: this.state.account })
                  .once('receipt', receipt => {
                    this.setState({ txpend: true })
                    this.setState({ txs: 1 })

                    this.state.sale_contract.methods
                      .BuyNFT(this.state.owner, key, this.state.imageData_price)
                      .send({ from: this.state.account })
                      .once('receipt', receipt => {
                        console.log('NFT Bought!')
                        document.getElementById('buyanNFT').innerHTML =
                          "<span style='color: #00ff5a !important;font-weight:bold;'>NFT Purchased, Go to your collection!</span>"
                        this.setState({ txpend: false })
                        this.setState({ txs: 0 })
                      })
                      .catch(error => {
                        // Transaction rejected or failed

                        alert(
                          'Transaction failed on NFT purchase, try to buy again!',
                        )
                        this.setState({ txpend: false })
                      })
                  })
                  .catch(error => {
                    // Transaction rejected or failed

                    alert('Transaction failed on SEBI approval!')
                    this.setState({ txpend: false })
                  })
              })
              .catch(error => {
                // Transaction rejected or failed

                alert('Transaction failed on Sebining from ONE!')
                this.setState({ txpend: false })
              })
          })
          .catch(error => {
            // Transaction rejected or failed

            alert('Transaction failed on ONE approval!')
            this.setState({ txpend: false })
          })
      })
      .catch(error => {
        console.log(error)
        alert('Failed to get Serabe/SEBI estimate!')
        this.setState({ txpend: false })
      })
  }

  giftNFT = key => {
    const web3 = window.web3

    this.setState({ txpend: true })
    this.setState({ txs: 2 })

    if (this.state.accttosend) {
      this.state.contract.methods
        .approveNFT('0x3B1644C3942aEb8dD3B8c7AdDfE8b0d38D7d7Bee', key)
        .send({ from: this.state.account })
        .once('receipt', receipt => {
          console.log('nft approved for gifting')
          document.getElementById('approvedsale').innerHTML =
            "<span style='color: #00ff5a !important;font-weight:bold;'>NFT Approved for Gifting</span>"
          this.setState({ txpend: true })
          this.setState({ txs: 1 })

          this.state.sale_contract.methods
            .GiftNFT(this.state.owner, key, this.state.accttosend)
            .send({ from: this.state.account })
            .once('receipt', receipt => {
              console.log('NFT Gifted!')
              document.getElementById('giftNFT').innerHTML =
                "<br /><span style='color: #00ff5a !important;font-weight:bold;'>NFT Gifted! Sweet!</span>"
              this.setState({ txpend: false })
              this.setState({ txs: 0 })
            })
            .catch(error => {
              // Transaction rejected or failed

              alert('Transaction failed on gifting!')
              this.setState({ txpend: false })
              this.setState({ txs: 0 })
            })
        })
        .catch(error => {
          // Transaction rejected or failed

          alert('Transaction failed on approval!')
          this.setState({ txpend: false })
          this.setState({ txs: 0 })
        })
      // }).catch(error => {
      //     // Transaction rejected or failed

      //     alert("Transaction failed!");
      //     this.setState({ txpend: false });
      // })
    } else {
      alert('Please enter in a ONE address to send to!')
      this.setState({ txpend: false })
    }
  }

  approveNFT = key => {
    this.setState({ txpend: true })
    this.setState({ txs: 2 })

    if (this.state.new_price == null) {
      this.setState({ new_price: this.state.imageData_price })

      this.state.new_price = this.state.imageData_price

      this.state.contract.methods
        .updatePrice(key, this.state.new_price)
        .send({ from: this.state.account })
        .once('receipt', receipt => {
          console.log('price updated')
          this.setState({ txpend: true })
          this.setState({ txs: 1 })

          this.state.contract.methods
            .approveNFT('0x3B1644C3942aEb8dD3B8c7AdDfE8b0d38D7d7Bee', key)
            .send({ from: this.state.account })
            .once('receipt', receipt => {
              console.log('nft approved for sale')
              document.getElementById('approvedsale').innerHTML =
                "<span style='color: #00ff5a !important;font-weight:bold;'>NFT Approved and Listed for sale!</span>"
              this.setState({ txpend: false })
              this.setState({ txs: 0 })
            })
            .catch(error => {
              // Transaction rejected or failed

              alert('Transaction failed on second tx!')
              this.setState({ txpend: false })
            })
        })
        .catch(error => {
          // Transaction rejected or failed

          alert('Transaction failed!')
          this.setState({ txpend: false })
        })
    } else {
      console.log(this.state.new_price)

      this.state.contract.methods
        .updatePrice(key, this.state.new_price)
        .send({ from: this.state.account })
        .once('receipt', receipt => {
          console.log('price updated')
          this.setState({ txpend: true })
          this.setState({ txs: 1 })

          this.state.contract.methods
            .approveNFT('0x3B1644C3942aEb8dD3B8c7AdDfE8b0d38D7d7Bee', key)
            .send({ from: this.state.account })
            .once('receipt', receipt => {
              console.log('nft approved for sale')
              document.getElementById('approvedsale').innerHTML =
                "<span style='color: #00ff5a !important;font-weight:bold;'>NFT Approved and Updated for sale!</span>"
              this.setState({ txpend: false })
              this.setState({ txs: 0 })
            })
            .catch(error => {
              // Transaction rejected or failed

              alert('Transaction failed on second tx!')
              this.setState({ txpend: false })
            })
        })
        .catch(error => {
          // Transaction rejected or failed

          alert('Transaction failed!')
          this.setState({ txpend: false })
        })
    }
  }
}
export default Fantums
