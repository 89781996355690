import React, { Component } from 'react'
import { useHistory, Link } from 'react-router-dom'

import Serabe from '../../abis/Serabe.json'
import SerabeNFT from '../../abis/SerabeNFT.json'
import SerabeNFTSale from '../../abis/SerabeNFTSale.json'
import SerabeAvatars from '../../abis/SerabeAvatars.json'
import SerabeVerified from '../../abis/SerabeVerified.json'
import SerabeNFTLikes from '../../abis/SerabeNFTLikes.json'

import LazyLoad, { forceCheck } from 'react-lazyload'

import ReactCanvasConfetti from 'react-canvas-confetti'

import ReactPlaceholder from 'react-placeholder'
import {
  TextBlock,
  MediaBlock,
  TextRow,
  RectShape,
  RoundShape,
} from 'react-placeholder/lib/placeholders'
import 'react-placeholder/lib/reactPlaceholder.css'

import { Progress } from 'react-sweet-progress'
import 'react-sweet-progress/lib/style.css'

import Modal from 'react-modal'

import Jazzicon, { jsNumberForAddress } from 'react-jazzicon'

import LoadingOverlay from 'react-loading-overlay'

import Web3 from 'web3'

Modal.setAppElement('#root')

const canvasStyles = {
  position: 'fixed',
  pointerEvents: 'none',
  width: '100%',
  height: '100%',
  zIndex: '999999',
  top: 0,
  left: 0,
}

const customStyle2 = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    backgroundColor: '#111',
    borderColor: '#004A8B',
    borderRadius: '15px',
    padding: '40px',
    color: '#FFF',
    bottom: 'auto',
    minWidth: '350px',
    maxWidth: '650px',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
}

class Stake extends Component {
  handleOpenSEBIModal() {
    this.setState({ showSEBIModal: true })
  }

  handleCloseSEBIModal() {
    this.setState({ showSEBIModal: false })
  }

  render() {
    return (
      <div>
        <LoadingOverlay
          active={this.state.txpend}
          spinner
          transition={false}
          text={'Waiting on...' + this.state.txs + ' transaction(s)'}
          styles={{
            overlay: base => ({
              ...base,
              background: 'rgba(0, 0, 0, 0.95)',
              position: 'fixed',
            }),
            wrapper: {
              width: '100%',
              height: '100%',
              borderRadius: '25px',
            },
          }}
        >
          <Modal
            animationDuration={1000}
            isOpen={this.state.showSEBIModal}
            contentLabel="Serabe LP Deposit"
            id="modalSerabe"
            align="center"
            style={customStyle2}
            overlayClassName="myoverlay"
            onRequestClose={this.handleCloseSEBIModal}
          >
            <button
              onClick={this.handleCloseSEBIModal}
              className="btn btn-primary"
              align="right"
              style={{ top: '15px', right: '15px', position: 'absolute' }}
            >
              X
            </button>
            <h2 align="center">
              <img src="/lplogo.png" border="0" height="66px" width="132px" />
            </h2>
            <br />
            <div align="center">
              <h3>
                Deposit your <strong>SEBI-ONE</strong> LP Tokens
              </h3>
              <br />
              <form
                onSubmit={event => {
                  event.preventDefault()
                  this.handleDeposit()
                }}
              >
                <input
                  type="text"
                  className="form-control"
                  placeholder={this.state.balanceLP.toFixed(4)}
                  onChange={event =>
                    this.setState({ amounttodep: event.target.value })
                  }
                  style={{ maxWidth: '200px' }}
                />
                <button
                  type="submit"
                  className="btn btn-primary rounded m-3 homeButton liquid"
                >
                  DEPOSIT
                </button>
              </form>
              <div align="center">OR</div>
              <form
                onSubmit={event => {
                  event.preventDefault()
                  this.handleDepositMax()
                }}
              >
                <button
                  type="submit"
                  className="btn btn-primary rounded m-3 liquid"
                >
                  DEPOSIT MAX
                </button>
              </form>
            </div>
          </Modal>
          <div className="head-title col-auto mx-4">
            <h4 className="mb-0 font-weight-normal">
              Serabe Liquidity Pool Stake
            </h4>
          </div>
          <div className="row home-adj">


            <div className="col-md-12" align="center">
              <div className="row">
                <div className="col-md-12">
                  <h2>
                    <span className="rainbowtxt1">SEBI</span>&nbsp;&nbsp;
                    <span className="rainbowtxt2">LIQUIDITY</span>
                    <br />
                    <span className="rainbowtxt3">STAKING POOL</span>
                  </h2>
                  &nbsp;
                  <br />
                  &nbsp;
                  <br />
                </div>

                <div className="col-md-6" style={{ marginTop: 'auto' }}>

                  <div
                    className="text-secondary my-auto align-middle align-self-center my-auto justify-content-center "
                    style={{
                      fontSize: '18px',
                      lineHeight: 1.5,
                      wordSpacing: 2,
                      background:
                        'radial-gradient(circle, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 50%, rgba(17,17,17,1) 100%)',
                      padding: '30px',
                      borderRadius: '15px',
                      paddingTop: '40px',
                      minHeight: '360px',
                      width: '80%',
                      color: '#aaa',
                      verticalAlign: 'middle',
                      display: 'table-cell',
                    }}
                  >
                    <div className="row">
                      <div className="col-12">
                        <strong>Total Serabe Liquid</strong>
                        <br />
                        <ReactPlaceholder
                          type="rect"
                          ready={this.state.totalliquidity}
                          showLoadingAnimation={true}
                          color="#333"
                          style={{
                            width: '250px',
                            height: '50px',
                            marginBottom: '15px',
                            borderRadius: '15px',
                          }}
                        >
                          <h1 className="text-light">
                            $
                            <strong>
                              {this.state.totalliquidity.toLocaleString('en', {
                                style: 'decimal',
                                maximumFractionDigits: 0,
                                minimumFractionDigits: 0,
                              })}
                            </strong>
                          </h1>
                        </ReactPlaceholder>
                      </div>
                    </div>
                    This is where you can{' '}
                    <strong>stake your SEBI/ONE LP Tokens</strong>
                    <br />
                    Earn SEBI rewards from supporting our liquidity pool on{' '}
                    <strong>Viperswap</strong>.<br />
                    <br />
                    <p>
                      <h4>
                        ≈83.3m SEBI per day split amongst all stakers
                        <br />
                        <span
                          id="countdown"
                          style={{ color: '#eee' }}
                        ></span>{' '}
                        remaining
                      </h4>
                    </p>
                    <ReactPlaceholder
                      type="rect"
                      ready={this.state.rewardRate}
                      showLoadingAnimation={true}
                      color="#333"
                      style={{
                        width: '250px',
                        height: '50px',
                        marginBottom: '15px',
                        borderRadius: '15px',
                      }}
                    >
                      <h4 style={{ fontSize: '18px' }}>
                        1 SEBI = $
                        {this.state.ftmusd.toString().slice(
                          0,
                          Number(this.state.ftmusd)
                            .toString()
                            .indexOf('.') + 3,
                        )}{' '}
                        USD
                      </h4>
                      <h3 className="text-light" style={{ fontSize: '22px' }}>
                        Est. ≈
                        {Math.trunc(
                          (((Number(5184000) *
                            (this.state.rewardRate / 1e18) *
                            60) /
                            5000000000) *
                            100) /
                            12,
                        )}
                        % APY
                      </h3>
                    </ReactPlaceholder>
                  </div>
                  <br />
                  <br />
                </div>
                <div className="col-md-6">
                  <div className="row">
                    <div className="col-md-6 text-light">
                      <br />
                      <img
                        src="/logo.png"
                        height="75px"
                        width="75px"
                        border="0"
                      />
                      <br />
                      <br />
                      <h3>SEBI Balance</h3>
                      <div
                        style={{
                          backgroundColor: '#000',
                          borderRadius: '15px',
                          padding: '10px',
                        }}
                      >
                        <ReactPlaceholder
                          type="rect"
                          ready={this.state.balance}
                          showLoadingAnimation={true}
                          color="#333"
                          style={{
                            width: '200px',
                            height: '60px',
                            borderRadius: '15px',
                          }}
                        >
                          {' '}
                          {this.state.balance.toString().slice(
                            0,
                            Number(this.state.balance)
                              .toString()
                              .indexOf('.') + 3,
                          )}{' '}
                          SEBI
                          <br />
                          ≈$
                          {Number(this.state.usdbal)
                            .toString()
                            .slice(
                              0,
                              Number(this.state.usdbal)
                                .toString()
                                .indexOf('.') + 3,
                            )}{' '}
                          USD
                        </ReactPlaceholder>
                      </div>
                    </div>
                    <div className="col-md-6 text-light">
                      <br />
                      <img
                        src="/lplogo.png"
                        height="75px"
                        width="150px"
                        border="0"
                      />
                      <br />
                      <br />
                      <h3>SEBI/FTM LP Balance</h3>
                      <div
                        style={{
                          backgroundColor: '#000',
                          borderRadius: '15px',
                          padding: '10px',
                        }}
                      >
                        <ReactPlaceholder
                          type="rect"
                          ready={this.state.balanceLP}
                          showLoadingAnimation={true}
                          color="#333"
                          style={{
                            width: '200px',
                            height: '60px',
                            borderRadius: '15px',
                          }}
                        >
                          {this.state.balanceLP.toString().slice(
                            0,
                            Number(this.state.balanceLP)
                              .toString()
                              .indexOf('.') + 5,
                          )}{' '}
                          LP
                          <br />
                          {this.state.percentageLP}% of liquidity total
                        </ReactPlaceholder>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6 text-light">
                      <br />
                      <img
                        src="/pool.png"
                        height="75px"
                        width="150px"
                        border="0"
                      />
                      <br />
                      <br />
                      <h3>Balance in Stake</h3>
                      <div
                        style={{
                          backgroundColor: '#000',
                          borderRadius: '15px',
                          padding: '10px',
                        }}
                      >
                        <ReactPlaceholder
                          type="rect"
                          ready={this.state.balPool}
                          showLoadingAnimation={true}
                          color="#333"
                          style={{
                            width: '200px',
                            height: '60px',
                            borderRadius: '15px',
                          }}
                        >
                          {Number(this.state.balPool).toFixed(2)} LP
                          <br />
                          {this.state.percentagePool}% of pool total
                        </ReactPlaceholder>
                      </div>
                    </div>
                    <div className="col-md-6 text-light">
                      <br />
                      <img
                        src="/logo.png"
                        height="75px"
                        width="75px"
                        border="0"
                      />
                      <br />
                      <br />
                      <h3>Rewards</h3>
                      <div
                        style={{
                          backgroundColor: '#000',
                          borderRadius: '15px',
                          padding: '10px',
                        }}
                      >
                        <ReactPlaceholder
                          type="rect"
                          ready={this.state.rewardbal}
                          showLoadingAnimation={true}
                          color="#333"
                          style={{
                            width: '200px',
                            height: '60px',
                            borderRadius: '15px',
                          }}
                        >
                          {this.state.earned.toString().slice(
                            0,
                            Number(this.state.earned)
                              .toString()
                              .indexOf('.') + 3,
                          )}{' '}
                          SEBI
                          <br />
                          ≈$
                          {Number(this.state.rewardbal)
                            .toString()
                            .slice(
                              0,
                              Number(this.state.rewardbal)
                                .toString()
                                .indexOf('.') + 3,
                            )}{' '}
                          USD
                        </ReactPlaceholder>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <br />
              <br />
              <div className="row">
                <div className="col-md-4">
                  <div id="claim" style={{ display: 'none' }}>
                    <form
                      onSubmit={event => {
                        event.preventDefault()
                        this.handleClaim()
                      }}
                    >
                      <button
                        type="submit"
                        className="btn btn-primary rounded m-3 homeButton liquid"
                      >
                        CLAIM
                      </button>
                    </form>
                  </div>
                </div>
                <div className="col-md-4">
                  <div id="approval" style={{ display: 'block' }}>
                    <form
                      onSubmit={event => {
                        event.preventDefault()
                        this.handleApprove('test')
                      }}
                    >
                      <button
                        type="submit"
                        className="btn btn-primary rounded m-3 homeButton liquid"
                      >
                        APPROVE
                      </button>
                    </form>
                  </div>
                  <div id="deposit" style={{ display: 'none' }}>
                    <button
                      className="btn btn-primary rounded m-3 homeButton liquid"
                      onClick={this.handleOpenSEBIModal}
                    >
                      DEPOSIT
                    </button>
                  </div>
                </div>
                <div className="col-md-4">
                  <div id="withdraw" style={{ display: 'none' }}>
                    <form
                      onSubmit={event => {
                        event.preventDefault()
                        this.handleWithdraw()
                      }}
                    >
                      <button
                        type="submit"
                        className="btn btn-primary rounded m-3 homeButton liquid"
                      >
                        WITHDRAW
                      </button>
                    </form>
                  </div>
                </div>
              </div>
              <br />
              <div className="row">
                <div
                  className="col-md-3"
                  align="center"
                  style={{ padding: '30px' }}
                >
                  <h3 className="text-light">
                    <p>
                      <i
                        className="fas fa-water fa-2x"
                        style={{ color: '#999' }}
                      ></i>
                    </p>
                    Get liquid
                  </h3>
                  <p className="text-secondary">
                    Once you’ve added both SEBI and ONE to our liquidity pool on
                    Spookyswap.finance you can then deposit them here to earn
                    additional SEBI!
                  </p>
                </div>
                <div
                  className="col-md-3"
                  align="center"
                  style={{ padding: '30px' }}
                >
                  <h3 className="text-light">
                    <p>
                      <i
                        className="fas fa-angle-double-down fa-2x"
                        style={{ color: '#999' }}
                      ></i>
                    </p>
                    Deposit LP
                  </h3>
                  <p className="text-secondary">
                    Click Approve, then enter in an amount of SEBI/ONE LP Tokens
                    to deposit and click "DEPOSIT". LP Tokens can be obtained
                    from putting in SEBI/ONE liquidity on ViperSwap
                  </p>
                </div>
                <div
                  className="col-md-3"
                  align="center"
                  style={{ padding: '30px' }}
                >
                  <h3 className="text-light">
                    <p>
                      <i
                        className="fas fa-coins fa-2x"
                        style={{ color: '#999' }}
                      ></i>
                    </p>
                    Earn
                  </h3>
                  <p className="text-secondary">
                    Earn SEBI from the fixed rate liquidity token staking pool
                    contract. As long as the pool period is open you will earn
                    rewards. Claim SEBI rewards and just hold to earn SEJU!
                  </p>
                </div>
                <div
                  className="col-md-3"
                  align="center"
                  style={{ padding: '30px' }}
                >
                  <h3 className="text-light">
                    <p>
                      <i
                        className="fas fa-shopping-bag fa-2x"
                        style={{ color: '#999' }}
                      ></i>
                    </p>
                    Buy a NFT
                  </h3>
                  <p className="text-secondary">
                    With the extra SEBI you are now earning from providing
                    liquidity and staking it, you can buy more NFTs! Or you can
                    just hold your SEBI which earns you SEJU!
                  </p>
                </div>
              </div>
              {/* TODO: GANTIIIIIIIIIIIIIIIIIIII */}
              <a
                type="button"
                href="https://viperswap.one/#/add/ONE/0x07625EFb8E31D7419427dDa993Ce68a41c04a5de"
                target="_blank"
                className="btn btn-primary rounded m-3 homeButton liquid2"
              >
                GET Viper LP
                <br />
                TOKENS
              </a>
              <br />
              <br />
              <div className="row justify-content-around">
                <p align="center" className="text-light">
                  Prices of SEBI and ONE in USD are powered by our Serabe
                  Harmony LINK Price Oracle Contract.
                </p>
              </div>
            </div>
          </div>
        </LoadingOverlay>
        <ReactCanvasConfetti
          refConfetti={this.getInstance}
          style={canvasStyles}
        />
      </div>
    )
  }
  constructor(props) {
    super(props)
    this.state = {
      account: '',
      contract: null,
      contractlp: null,
      sale_contract: null,
      showSEBIModal: false,
      mcap: 0,
      totalSupply: 0,
      balance: 0,
      rewardRate: 0,
      rewards: 0,
      earned: 0,
      ftmusd: 0,
      balanceInPool: 0,
      rewardbal: 0,
      balPool: 0,
      percentagePool: 0,
      approved: false,
      balanceLP: 0,
      usdBal: 0,
      amounttodep: 0,
      txpend: false,
      totalliquidity: 0,
      txs: 0,
      percentageLP: 0,
    }
    this.handleOpenSEBIModal = this.handleOpenSEBIModal.bind(this)
    this.handleCloseSEBIModal = this.handleCloseSEBIModal.bind(this)
  }

  async componentWillMount() {
    await this.loadBlockchainData()
  }

  handleApprove = async () => {
    const web3 = window.web3

    const accounts = await web3.eth.getAccounts()
    const account = accounts[0]

    this.setState({ txpend: true })
    this.setState({ txs: 1 })

    const abillp = [
      {
        inputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'constructor',
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: 'address',
            name: 'owner',
            type: 'address',
          },
          {
            indexed: true,
            internalType: 'address',
            name: 'spender',
            type: 'address',
          },
          {
            indexed: false,
            internalType: 'uint256',
            name: 'value',
            type: 'uint256',
          },
        ],
        name: 'Approval',
        type: 'event',
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: 'address',
            name: 'sender',
            type: 'address',
          },
          {
            indexed: false,
            internalType: 'uint256',
            name: 'amount0',
            type: 'uint256',
          },
          {
            indexed: false,
            internalType: 'uint256',
            name: 'amount1',
            type: 'uint256',
          },
          {
            indexed: true,
            internalType: 'address',
            name: 'to',
            type: 'address',
          },
        ],
        name: 'Burn',
        type: 'event',
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: 'address',
            name: 'sender',
            type: 'address',
          },
          {
            indexed: false,
            internalType: 'uint256',
            name: 'amount0',
            type: 'uint256',
          },
          {
            indexed: false,
            internalType: 'uint256',
            name: 'amount1',
            type: 'uint256',
          },
        ],
        name: 'Mint',
        type: 'event',
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: 'address',
            name: 'sender',
            type: 'address',
          },
          {
            indexed: false,
            internalType: 'uint256',
            name: 'amount0In',
            type: 'uint256',
          },
          {
            indexed: false,
            internalType: 'uint256',
            name: 'amount1In',
            type: 'uint256',
          },
          {
            indexed: false,
            internalType: 'uint256',
            name: 'amount0Out',
            type: 'uint256',
          },
          {
            indexed: false,
            internalType: 'uint256',
            name: 'amount1Out',
            type: 'uint256',
          },
          {
            indexed: true,
            internalType: 'address',
            name: 'to',
            type: 'address',
          },
        ],
        name: 'Swap',
        type: 'event',
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: false,
            internalType: 'uint112',
            name: 'reserve0',
            type: 'uint112',
          },
          {
            indexed: false,
            internalType: 'uint112',
            name: 'reserve1',
            type: 'uint112',
          },
        ],
        name: 'Sync',
        type: 'event',
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: 'address',
            name: 'from',
            type: 'address',
          },
          {
            indexed: true,
            internalType: 'address',
            name: 'to',
            type: 'address',
          },
          {
            indexed: false,
            internalType: 'uint256',
            name: 'value',
            type: 'uint256',
          },
        ],
        name: 'Transfer',
        type: 'event',
      },
      {
        constant: true,
        inputs: [],
        name: 'DOMAIN_SEPARATOR',
        outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: true,
        inputs: [],
        name: 'MINIMUM_LIQUIDITY',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: true,
        inputs: [],
        name: 'PERMIT_TYPEHASH',
        outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: true,
        inputs: [
          { internalType: 'address', name: '', type: 'address' },
          { internalType: 'address', name: '', type: 'address' },
        ],
        name: 'allowance',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: false,
        inputs: [
          { internalType: 'address', name: 'spender', type: 'address' },
          { internalType: 'uint256', name: 'value', type: 'uint256' },
        ],
        name: 'approve',
        outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        constant: true,
        inputs: [{ internalType: 'address', name: '', type: 'address' }],
        name: 'balanceOf',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: false,
        inputs: [{ internalType: 'address', name: 'to', type: 'address' }],
        name: 'burn',
        outputs: [
          { internalType: 'uint256', name: 'amount0', type: 'uint256' },
          { internalType: 'uint256', name: 'amount1', type: 'uint256' },
        ],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        constant: true,
        inputs: [],
        name: 'decimals',
        outputs: [{ internalType: 'uint8', name: '', type: 'uint8' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: true,
        inputs: [],
        name: 'factory',
        outputs: [{ internalType: 'address', name: '', type: 'address' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: true,
        inputs: [],
        name: 'getReserves',
        outputs: [
          { internalType: 'uint112', name: '_reserve0', type: 'uint112' },
          { internalType: 'uint112', name: '_reserve1', type: 'uint112' },
          {
            internalType: 'uint32',
            name: '_blockTimestampLast',
            type: 'uint32',
          },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: false,
        inputs: [
          { internalType: 'address', name: '_token0', type: 'address' },
          { internalType: 'address', name: '_token1', type: 'address' },
        ],
        name: 'initialize',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        constant: true,
        inputs: [],
        name: 'kLast',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: false,
        inputs: [{ internalType: 'address', name: 'to', type: 'address' }],
        name: 'mint',
        outputs: [
          { internalType: 'uint256', name: 'liquidity', type: 'uint256' },
        ],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        constant: true,
        inputs: [],
        name: 'name',
        outputs: [{ internalType: 'string', name: '', type: 'string' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: true,
        inputs: [{ internalType: 'address', name: '', type: 'address' }],
        name: 'nonces',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: false,
        inputs: [
          { internalType: 'address', name: 'owner', type: 'address' },
          { internalType: 'address', name: 'spender', type: 'address' },
          { internalType: 'uint256', name: 'value', type: 'uint256' },
          { internalType: 'uint256', name: 'deadline', type: 'uint256' },
          { internalType: 'uint8', name: 'v', type: 'uint8' },
          { internalType: 'bytes32', name: 'r', type: 'bytes32' },
          { internalType: 'bytes32', name: 's', type: 'bytes32' },
        ],
        name: 'permit',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        constant: true,
        inputs: [],
        name: 'price0CumulativeLast',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: true,
        inputs: [],
        name: 'price1CumulativeLast',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: false,
        inputs: [{ internalType: 'address', name: 'to', type: 'address' }],
        name: 'skim',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        constant: false,
        inputs: [
          { internalType: 'uint256', name: 'amount0Out', type: 'uint256' },
          { internalType: 'uint256', name: 'amount1Out', type: 'uint256' },
          { internalType: 'address', name: 'to', type: 'address' },
          { internalType: 'bytes', name: 'data', type: 'bytes' },
        ],
        name: 'swap',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        constant: true,
        inputs: [],
        name: 'symbol',
        outputs: [{ internalType: 'string', name: '', type: 'string' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: false,
        inputs: [],
        name: 'sync',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        constant: true,
        inputs: [],
        name: 'token0',
        outputs: [{ internalType: 'address', name: '', type: 'address' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: true,
        inputs: [],
        name: 'token1',
        outputs: [{ internalType: 'address', name: '', type: 'address' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: true,
        inputs: [],
        name: 'totalSupply',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: false,
        inputs: [
          { internalType: 'address', name: 'to', type: 'address' },
          { internalType: 'uint256', name: 'value', type: 'uint256' },
        ],
        name: 'transfer',
        outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        constant: false,
        inputs: [
          { internalType: 'address', name: 'from', type: 'address' },
          { internalType: 'address', name: 'to', type: 'address' },
          { internalType: 'uint256', name: 'value', type: 'uint256' },
        ],
        name: 'transferFrom',
        outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
      },
    ]
    const contractlp = new web3.eth.Contract(
      abillp,
      '0x1ecdab40fe2424a511fd35250ddc37a0ae7c1bb9',
    )
    contractlp.methods
      .approve(
        '0x4Fbca39bB61951b46Ac160930583578f5AF4C162',
        '999999999999900000000000000',
      )
      .send({ from: account })
      .once('receipt', receipt => {
        console.log(receipt)
        console.log('Approved')

        this.setState({ txpend: false })
        this.setState({ txs: 0 })

        localStorage.setItem('stakeapprove', 'true')
        this.setState({ approved: true })

        document.getElementById('approval').style.display = 'none'
        document.getElementById('deposit').style.display = 'block'
        document.getElementById('withdraw').style.display = 'block'
        document.getElementById('claim').style.display = 'block'
      })
      .catch(error => {
        console.log(error)
        this.setState({ txpend: false })
        this.setState({ txs: 0 })
      })
  }

  handleDeposit = async () => {
    const testnetHarmonyRpc = 'https://api.s0.b.hmny.io'
    const web3ftm = new Web3(testnetHarmonyRpc)
    const web3 = window.web3

    const accounts = await web3.eth.getAccounts()
    const account = accounts[0]

    this.setState({ txpend: true })
    this.setState({ txs: 1 })

    const abipool = [
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: 'address',
            name: 'previousOwner',
            type: 'address',
          },
          {
            indexed: true,
            internalType: 'address',
            name: 'newOwner',
            type: 'address',
          },
        ],
        name: 'OwnershipTransferred',
        type: 'event',
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: false,
            internalType: 'uint256',
            name: 'reward',
            type: 'uint256',
          },
        ],
        name: 'RewardAdded',
        type: 'event',
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: 'address',
            name: 'user',
            type: 'address',
          },
          {
            indexed: false,
            internalType: 'uint256',
            name: 'reward',
            type: 'uint256',
          },
        ],
        name: 'RewardPaid',
        type: 'event',
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: 'address',
            name: 'user',
            type: 'address',
          },
          {
            indexed: false,
            internalType: 'uint256',
            name: 'amount',
            type: 'uint256',
          },
        ],
        name: 'Staked',
        type: 'event',
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: 'address',
            name: 'user',
            type: 'address',
          },
          {
            indexed: false,
            internalType: 'uint256',
            name: 'amount',
            type: 'uint256',
          },
        ],
        name: 'Withdrawn',
        type: 'event',
      },
      {
        constant: true,
        inputs: [],
        name: 'DURATION',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: true,
        inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
        name: 'balanceOf',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: true,
        inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
        name: 'earned',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: false,
        inputs: [],
        name: 'exit',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        constant: false,
        inputs: [],
        name: 'getReward',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        constant: true,
        inputs: [],
        name: 'isOwner',
        outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: true,
        inputs: [],
        name: 'lastTimeRewardApplicable',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: true,
        inputs: [],
        name: 'lastUpdateTime',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: false,
        inputs: [{ internalType: 'uint256', name: 'reward', type: 'uint256' }],
        name: 'notifyRewardAmount',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        constant: true,
        inputs: [],
        name: 'owner',
        outputs: [{ internalType: 'address', name: '', type: 'address' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: true,
        inputs: [],
        name: 'periodFinish',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: false,
        inputs: [],
        name: 'renounceOwnership',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        constant: true,
        inputs: [],
        name: 'rewardDistribution',
        outputs: [{ internalType: 'address', name: '', type: 'address' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: true,
        inputs: [],
        name: 'rewardPerToken',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: true,
        inputs: [],
        name: 'rewardPerTokenStored',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: true,
        inputs: [],
        name: 'rewardRate',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: true,
        inputs: [{ internalType: 'address', name: '', type: 'address' }],
        name: 'rewards',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: true,
        inputs: [],
        name: 'sebi',
        outputs: [
          { internalType: 'contract IERC20', name: '', type: 'address' },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: true,
        inputs: [],
        name: 'sebiinlp',
        outputs: [
          { internalType: 'contract IERC20', name: '', type: 'address' },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: false,
        inputs: [
          {
            internalType: 'address',
            name: '_rewardDistribution',
            type: 'address',
          },
        ],
        name: 'setRewardDistribution',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        constant: false,
        inputs: [{ internalType: 'uint256', name: 'amount', type: 'uint256' }],
        name: 'stake',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        constant: true,
        inputs: [],
        name: 'totalSupply',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: false,
        inputs: [
          { internalType: 'address', name: 'newOwner', type: 'address' },
        ],
        name: 'transferOwnership',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        constant: true,
        inputs: [{ internalType: 'address', name: '', type: 'address' }],
        name: 'userRewardPerTokenPaid',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: false,
        inputs: [{ internalType: 'uint256', name: 'amount', type: 'uint256' }],
        name: 'withdraw',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
      },
    ]
    const contractpool = new web3.eth.Contract(
      abipool,
      'sebi0x4Fbca39bB61951b46Ac160930583578f5AF4C162',
    )
    contractpool.methods
      .stake(web3ftm.utils.toWei(this.state.amounttodep, 'ether'))
      .send({ from: account })
      .once('receipt', receipt => {
        console.log(receipt)
        console.log('Deposited LP Tokens!')

        this.setState({ txpend: false })
        this.setState({ txs: 0 })

        // localStorage.setItem('stakeapprove', 'true');

        // document.getElementById("approval").style.display = "none";
        this.handleCloseSEBIModal()
        document.getElementById('deposit').innerHTML =
          "<h4 style='color: #00ff5a;margin-top:30px;'>Deposited SEBI LP Tokens!</h4>"
        this.handlerFire()
      })
      .catch(error => {
        console.log(error)
        this.setState({ txpend: false })
        this.setState({ txs: 0 })
      })
  }

  handleDepositMax = async () => {
    const testnetHarmonyRpc = 'https://api.s0.b.hmny.io'
    const web3ftm = new Web3(testnetHarmonyRpc)
    const web3 = window.web3

    const accounts = await web3.eth.getAccounts()
    const account = accounts[0]

    this.setState({ txpend: true })
    this.setState({ txs: 1 })

    const abipool = [
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: 'address',
            name: 'previousOwner',
            type: 'address',
          },
          {
            indexed: true,
            internalType: 'address',
            name: 'newOwner',
            type: 'address',
          },
        ],
        name: 'OwnershipTransferred',
        type: 'event',
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: false,
            internalType: 'uint256',
            name: 'reward',
            type: 'uint256',
          },
        ],
        name: 'RewardAdded',
        type: 'event',
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: 'address',
            name: 'user',
            type: 'address',
          },
          {
            indexed: false,
            internalType: 'uint256',
            name: 'reward',
            type: 'uint256',
          },
        ],
        name: 'RewardPaid',
        type: 'event',
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: 'address',
            name: 'user',
            type: 'address',
          },
          {
            indexed: false,
            internalType: 'uint256',
            name: 'amount',
            type: 'uint256',
          },
        ],
        name: 'Staked',
        type: 'event',
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: 'address',
            name: 'user',
            type: 'address',
          },
          {
            indexed: false,
            internalType: 'uint256',
            name: 'amount',
            type: 'uint256',
          },
        ],
        name: 'Withdrawn',
        type: 'event',
      },
      {
        constant: true,
        inputs: [],
        name: 'DURATION',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: true,
        inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
        name: 'balanceOf',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: true,
        inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
        name: 'earned',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: false,
        inputs: [],
        name: 'exit',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        constant: false,
        inputs: [],
        name: 'getReward',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        constant: true,
        inputs: [],
        name: 'isOwner',
        outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: true,
        inputs: [],
        name: 'lastTimeRewardApplicable',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: true,
        inputs: [],
        name: 'lastUpdateTime',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: false,
        inputs: [{ internalType: 'uint256', name: 'reward', type: 'uint256' }],
        name: 'notifyRewardAmount',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        constant: true,
        inputs: [],
        name: 'owner',
        outputs: [{ internalType: 'address', name: '', type: 'address' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: true,
        inputs: [],
        name: 'periodFinish',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: false,
        inputs: [],
        name: 'renounceOwnership',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        constant: true,
        inputs: [],
        name: 'rewardDistribution',
        outputs: [{ internalType: 'address', name: '', type: 'address' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: true,
        inputs: [],
        name: 'rewardPerToken',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: true,
        inputs: [],
        name: 'rewardPerTokenStored',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: true,
        inputs: [],
        name: 'rewardRate',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: true,
        inputs: [{ internalType: 'address', name: '', type: 'address' }],
        name: 'rewards',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: false,
        inputs: [
          {
            internalType: 'address',
            name: '_rewardDistribution',
            type: 'address',
          },
        ],
        name: 'setRewardDistribution',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        constant: false,
        inputs: [{ internalType: 'uint256', name: 'amount', type: 'uint256' }],
        name: 'stake',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        constant: true,
        inputs: [],
        name: 'sebi',
        outputs: [
          { internalType: 'contract IERC20', name: '', type: 'address' },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: true,
        inputs: [],
        name: 'sebiinlp',
        outputs: [
          { internalType: 'contract IERC20', name: '', type: 'address' },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: true,
        inputs: [],
        name: 'totalSupply',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: false,
        inputs: [
          { internalType: 'address', name: 'newOwner', type: 'address' },
        ],
        name: 'transferOwnership',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        constant: true,
        inputs: [{ internalType: 'address', name: '', type: 'address' }],
        name: 'userRewardPerTokenPaid',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: false,
        inputs: [{ internalType: 'uint256', name: 'amount', type: 'uint256' }],
        name: 'withdraw',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
      },
    ]
    const contractpool = new web3.eth.Contract(
      abipool,
      '0x4Fbca39bB61951b46Ac160930583578f5AF4C162',
    ) //SERABEPOOL

    function toPlainString(num) {
      return ('' + +num).replace(/(-?)(\d*)\.?(\d*)e([+-]\d+)/, function(
        a,
        b,
        c,
        d,
        e,
      ) {
        return e < 0
          ? b + '0.' + Array(1 - e - c.length).join(0) + c + d
          : b + c + d + Array(e - d.length + 1).join(0)
      })
    }

    const balance = toPlainString(
      this.state.balanceLP * 1e18 - this.state.balanceLP * 1e18 * 0.00001,
    )

    console.log(balance)

    if (this.state.balanceLP > 0) {
      const balLP = balance.toString()
      // console.log(balLP)
      contractpool.methods
        .stake(balLP)
        .send({ from: account })
        .once('receipt', receipt => {
          console.log(receipt)
          console.log('Deposited LP Tokens!')

          this.setState({ txpend: false })
          this.setState({ txs: 0 })

          // localStorage.setItem('stakeapprove', 'true');

          // document.getElementById("approval").style.display = "none";
          this.handleCloseSEBIModal()
          document.getElementById('deposit').innerHTML =
            "<h4 style='color: #00ff5a;margin-top:30px;'>Deposited SEBI LP Tokens!</h4>"
          this.handlerFire()
        })
        .catch(error => {
          console.log(error)
          this.setState({ txpend: false })
          this.setState({ txs: 0 })
        })
    }
  }

  makeShot = (particleRatio, opts) => {
    this.animationInstance &&
      this.animationInstance({
        ...opts,
        origin: { y: 0.7 },
        particleCount: Math.floor(200 * particleRatio),
      })
  }

  fire = () => {
    this.makeShot(0.25, {
      spread: 26,
      startVelocity: 55,
    })

    this.makeShot(0.2, {
      spread: 60,
    })

    this.makeShot(0.35, {
      spread: 100,
      decay: 0.91,
      scalar: 0.8,
    })

    this.makeShot(0.1, {
      spread: 120,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.2,
    })

    this.makeShot(0.1, {
      spread: 120,
      startVelocity: 45,
    })
  }

  handlerFire = () => {
    this.fire()
  }

  getInstance = instance => {
    this.animationInstance = instance
  }

  handleWithdraw = async () => {
    const testnetHarmonyRpc = 'https://api.s0.b.hmny.io'
    const web3ftm = new Web3(testnetHarmonyRpc)
    const web3 = window.web3

    const accounts = await web3.eth.getAccounts()
    const account = accounts[0]

    this.setState({ txpend: true })
    this.setState({ txs: 1 })

    const abipool = [
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: 'address',
            name: 'previousOwner',
            type: 'address',
          },
          {
            indexed: true,
            internalType: 'address',
            name: 'newOwner',
            type: 'address',
          },
        ],
        name: 'OwnershipTransferred',
        type: 'event',
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: false,
            internalType: 'uint256',
            name: 'reward',
            type: 'uint256',
          },
        ],
        name: 'RewardAdded',
        type: 'event',
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: 'address',
            name: 'user',
            type: 'address',
          },
          {
            indexed: false,
            internalType: 'uint256',
            name: 'reward',
            type: 'uint256',
          },
        ],
        name: 'RewardPaid',
        type: 'event',
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: 'address',
            name: 'user',
            type: 'address',
          },
          {
            indexed: false,
            internalType: 'uint256',
            name: 'amount',
            type: 'uint256',
          },
        ],
        name: 'Staked',
        type: 'event',
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: 'address',
            name: 'user',
            type: 'address',
          },
          {
            indexed: false,
            internalType: 'uint256',
            name: 'amount',
            type: 'uint256',
          },
        ],
        name: 'Withdrawn',
        type: 'event',
      },
      {
        constant: true,
        inputs: [],
        name: 'DURATION',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: true,
        inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
        name: 'balanceOf',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: true,
        inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
        name: 'earned',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: false,
        inputs: [],
        name: 'exit',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        constant: false,
        inputs: [],
        name: 'getReward',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        constant: true,
        inputs: [],
        name: 'isOwner',
        outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: true,
        inputs: [],
        name: 'lastTimeRewardApplicable',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: true,
        inputs: [],
        name: 'lastUpdateTime',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: false,
        inputs: [{ internalType: 'uint256', name: 'reward', type: 'uint256' }],
        name: 'notifyRewardAmount',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        constant: true,
        inputs: [],
        name: 'owner',
        outputs: [{ internalType: 'address', name: '', type: 'address' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: true,
        inputs: [],
        name: 'periodFinish',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: false,
        inputs: [],
        name: 'renounceOwnership',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        constant: true,
        inputs: [],
        name: 'rewardDistribution',
        outputs: [{ internalType: 'address', name: '', type: 'address' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: true,
        inputs: [],
        name: 'rewardPerToken',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: true,
        inputs: [],
        name: 'rewardPerTokenStored',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: true,
        inputs: [],
        name: 'rewardRate',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: true,
        inputs: [{ internalType: 'address', name: '', type: 'address' }],
        name: 'rewards',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: false,
        inputs: [
          {
            internalType: 'address',
            name: '_rewardDistribution',
            type: 'address',
          },
        ],
        name: 'setRewardDistribution',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        constant: false,
        inputs: [{ internalType: 'uint256', name: 'amount', type: 'uint256' }],
        name: 'stake',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        constant: true,
        inputs: [],
        name: 'sebi',
        outputs: [
          { internalType: 'contract IERC20', name: '', type: 'address' },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: true,
        inputs: [],
        name: 'sebiinlp',
        outputs: [
          { internalType: 'contract IERC20', name: '', type: 'address' },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: true,
        inputs: [],
        name: 'totalSupply',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: false,
        inputs: [
          { internalType: 'address', name: 'newOwner', type: 'address' },
        ],
        name: 'transferOwnership',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        constant: true,
        inputs: [{ internalType: 'address', name: '', type: 'address' }],
        name: 'userRewardPerTokenPaid',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: false,
        inputs: [{ internalType: 'uint256', name: 'amount', type: 'uint256' }],
        name: 'withdraw',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
      },
    ]

    const contractpool = new web3.eth.Contract(
      abipool,
      'sebi0x4Fbca39bB61951b46Ac160930583578f5AF4C162',
    )

    contractpool.methods
      .exit()
      .send({ from: account })
      .once('receipt', receipt => {
        console.log(receipt)
        console.log('Withdrawed LP Tokens!')

        this.setState({ txpend: false })
        this.setState({ txs: 0 })

        // localStorage.setItem('stakeapprove', 'true');

        // document.getElementById("approval").style.display = "none";
        document.getElementById('withdraw').innerHTML =
          "<h4 style='color: #00ff5a;margin-top:30px;'>Withdrew all SEBI LP Tokens and rewards!</h4>"
      })
      .catch(error => {
        console.log(error)
        this.setState({ txpend: false })
        this.setState({ txs: 0 })
      })
  }

  handleClaim = async () => {
    const testnetHarmonyRpc = 'https://api.s0.b.hmny.io'
    const web3ftm = new Web3(testnetHarmonyRpc)
    const web3 = window.web3

    const accounts = await web3.eth.getAccounts()
    const account = accounts[0]

    this.setState({ txpend: true })
    this.setState({ txs: 1 })

    const abipool = [
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: 'address',
            name: 'previousOwner',
            type: 'address',
          },
          {
            indexed: true,
            internalType: 'address',
            name: 'newOwner',
            type: 'address',
          },
        ],
        name: 'OwnershipTransferred',
        type: 'event',
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: false,
            internalType: 'uint256',
            name: 'reward',
            type: 'uint256',
          },
        ],
        name: 'RewardAdded',
        type: 'event',
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: 'address',
            name: 'user',
            type: 'address',
          },
          {
            indexed: false,
            internalType: 'uint256',
            name: 'reward',
            type: 'uint256',
          },
        ],
        name: 'RewardPaid',
        type: 'event',
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: 'address',
            name: 'user',
            type: 'address',
          },
          {
            indexed: false,
            internalType: 'uint256',
            name: 'amount',
            type: 'uint256',
          },
        ],
        name: 'Staked',
        type: 'event',
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: 'address',
            name: 'user',
            type: 'address',
          },
          {
            indexed: false,
            internalType: 'uint256',
            name: 'amount',
            type: 'uint256',
          },
        ],
        name: 'Withdrawn',
        type: 'event',
      },
      {
        constant: true,
        inputs: [],
        name: 'DURATION',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: true,
        inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
        name: 'balanceOf',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: true,
        inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
        name: 'earned',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: false,
        inputs: [],
        name: 'exit',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        constant: false,
        inputs: [],
        name: 'getReward',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        constant: true,
        inputs: [],
        name: 'isOwner',
        outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: true,
        inputs: [],
        name: 'lastTimeRewardApplicable',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: true,
        inputs: [],
        name: 'lastUpdateTime',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: false,
        inputs: [{ internalType: 'uint256', name: 'reward', type: 'uint256' }],
        name: 'notifyRewardAmount',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        constant: true,
        inputs: [],
        name: 'owner',
        outputs: [{ internalType: 'address', name: '', type: 'address' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: true,
        inputs: [],
        name: 'periodFinish',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: false,
        inputs: [],
        name: 'renounceOwnership',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        constant: true,
        inputs: [],
        name: 'rewardDistribution',
        outputs: [{ internalType: 'address', name: '', type: 'address' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: true,
        inputs: [],
        name: 'rewardPerToken',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: true,
        inputs: [],
        name: 'rewardPerTokenStored',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: true,
        inputs: [],
        name: 'rewardRate',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: true,
        inputs: [{ internalType: 'address', name: '', type: 'address' }],
        name: 'rewards',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: false,
        inputs: [
          {
            internalType: 'address',
            name: '_rewardDistribution',
            type: 'address',
          },
        ],
        name: 'setRewardDistribution',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        constant: false,
        inputs: [{ internalType: 'uint256', name: 'amount', type: 'uint256' }],
        name: 'stake',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        constant: true,
        inputs: [],
        name: 'sebi',
        outputs: [
          { internalType: 'contract IERC20', name: '', type: 'address' },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: true,
        inputs: [],
        name: 'sebiinlp',
        outputs: [
          { internalType: 'contract IERC20', name: '', type: 'address' },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: true,
        inputs: [],
        name: 'totalSupply',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: false,
        inputs: [
          { internalType: 'address', name: 'newOwner', type: 'address' },
        ],
        name: 'transferOwnership',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        constant: true,
        inputs: [{ internalType: 'address', name: '', type: 'address' }],
        name: 'userRewardPerTokenPaid',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: false,
        inputs: [{ internalType: 'uint256', name: 'amount', type: 'uint256' }],
        name: 'withdraw',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
      },
    ]

    const contractpool = new web3.eth.Contract(
      abipool,
      'sebi0x4Fbca39bB61951b46Ac160930583578f5AF4C162',
    )

    contractpool.methods
      .getReward()
      .send({ from: account })
      .once('receipt', receipt => {
        console.log(receipt)
        console.log('Claimed LP Tokens!')

        this.setState({ txpend: false })
        this.setState({ txs: 0 })

        // localStorage.setItem('stakeapprove', 'true');

        // document.getElementById("approval").style.display = "none";
        document.getElementById('claim').innerHTML =
          "<h4 style='color: #00ff5a;margin-top:30px;'>Claimed all SEBI rewards!</h4>"
      })
      .catch(error => {
        console.log(error)
        this.setState({ txpend: false })
        this.setState({ txs: 0 })
      })
  }

  async loadBlockchainData() {
    // window.loaded_web3 = false;

    function randomNumber(min, max) {
      return Math.random() * (max - min) + min
    }

    var end = new Date('10/04/2022 2:00 AM')

    var _second = 1000
    var _minute = _second * 60
    var _hour = _minute * 60
    var _day = _hour * 24
    var timer

    function showRemaining() {
      var now = new Date()
      var distance = end - now
      if (distance < 0) {
        clearInterval(timer)
        document.getElementById('countdown').innerHTML = 'EXPIRED!'

        return
      }
      var days = Math.floor(distance / _day)
      var hours = Math.floor((distance % _day) / _hour)
      var minutes = Math.floor((distance % _hour) / _minute)
      var seconds = Math.floor((distance % _minute) / _second)

      document.getElementById('countdown').innerHTML = days + ' d '
      document.getElementById('countdown').innerHTML += hours + ' h '
      document.getElementById('countdown').innerHTML += minutes + ' m '
      document.getElementById('countdown').innerHTML += seconds + ' s'
    }

    timer = setInterval(showRemaining, 1000)

    const web3 = window.web3

    const testnetHarmonyRpc = 'https://api.s0.b.hmny.io'
    const web3t = new Web3(testnetHarmonyRpc)
    // Load account
    const accounts = await web3.eth.getAccounts()
    this.setState({ account: accounts[0] })

    const networkId = 1666700000

    const blackListed = [238, 239]
    this.setState({ blackListed })

    const sale_networkData = SerabeNFTSale.networks[networkId]
    const sale_abi = SerabeNFTSale.abi
    const sale_address = sale_networkData.address
    const sale_contract = new web3t.eth.Contract(sale_abi, sale_address)
    this.setState({ sale_contract })

    const networkData = SerabeNFT.networks[networkId]
    const abi = SerabeNFT.abi
    const address = networkData.address
    const contract = new web3t.eth.Contract(abi, address)

    const abimain = Serabe.abi
    const addressmain = '0x07625EFb8E31D7419427dDa993Ce68a41c04a5de' //Serabe Token
    const contractmain = new web3.eth.Contract(abimain, addressmain)

    const abiv = SerabeVerified.abi
    const addv = '0x44434382a732494325202c3607c267835aF08b13' //SerabeVerified
    const contractv = new web3t.eth.Contract(abiv, addv)

    const abia = SerabeAvatars.abi
    const addr = '0xeB973420cb687A2301B0daE9ae3064DdA9474382' //SerabeAvatars
    const contractav = new web3t.eth.Contract(abia, addr)

    const abip = [
      { inputs: [], stateMutability: 'nonpayable', type: 'constructor' },
      {
        inputs: [],
        name: 'getLatestFTMPrice',
        outputs: [{ internalType: 'int256', name: '', type: 'int256' }],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [
          { internalType: 'address', name: 'pairAddress', type: 'address' },
          { internalType: 'uint256', name: 'amount', type: 'uint256' },
        ],
        name: 'getLatestTokenPrice',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function',
      },
    ]
    const addp = '0xF6377852a6Aa9487f8423336d334fF2f24ED497A' //SerabePriceOracle

    const contractp = new web3t.eth.Contract(abip, addp)
    this.setState({ contractp })

    const ftmprice = (await contractp.methods.getLatestFTMPrice().call()) / 1e8
    const sebiperftm = await contractp.methods
      .getLatestTokenPrice('0x1ecdab40fe2424a511fd35250ddc37a0ae7c1bb9', 1)
      .call() // PAIRS
    const sebiusd = ftmprice / (sebiperftm / 1e18)
    const mcap = Number(100000000000 * sebiusd)

    this.setState({ mcap })

    this.setState({ ftmusd: ftmprice })

    const abilike = SerabeNFTLikes.abi
    const contractlike = new web3t.eth.Contract(
      abilike,
      '0x68a21eCB96E683E85251E1B01ac0FC8df690EE2A',
    ) //SerabeNFTLikes

    const abiblack = [
      { inputs: [], stateMutability: 'nonpayable', type: 'constructor' },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: 'address',
            name: 'hashAddress',
            type: 'address',
          },
          {
            indexed: false,
            internalType: 'bool',
            name: 'blacklisted',
            type: 'bool',
          },
        ],
        name: 'SetBlackListedAddress',
        type: 'event',
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: 'uint256',
            name: 'nftID',
            type: 'uint256',
          },
          {
            indexed: false,
            internalType: 'bool',
            name: 'blacklisted',
            type: 'bool',
          },
        ],
        name: 'SetBlackListedNFT',
        type: 'event',
      },
      {
        inputs: [],
        name: 'AddyCount',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [],
        name: 'IDCount',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [
          { internalType: 'address', name: 'blAddress', type: 'address' },
        ],
        name: 'getBlackListedAddress',
        outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [{ internalType: 'uint256', name: 'nftID', type: 'uint256' }],
        name: 'getBlackListedNFT',
        outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        name: 'idupdates',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [],
        name: 'owner',
        outputs: [{ internalType: 'address', name: '', type: 'address' }],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [
          { internalType: 'address', name: 'addy', type: 'address' },
          { internalType: 'bool', name: 'blacklisted', type: 'bool' },
        ],
        name: 'setBlackListedAddress',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        inputs: [
          { internalType: 'uint256', name: 'nftID', type: 'uint256' },
          { internalType: 'bool', name: 'blacklisted', type: 'bool' },
        ],
        name: 'setBlackListedNFT',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        name: 'updates',
        outputs: [{ internalType: 'address', name: '', type: 'address' }],
        stateMutability: 'view',
        type: 'function',
      },
    ]
    const contractblack = new web3t.eth.Contract(
      abiblack,
      '0x2f2B7ae467E4f36B0F8f481481E58bFd1a4ef658',
    )

    const abipool = [
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: 'address',
            name: 'previousOwner',
            type: 'address',
          },
          {
            indexed: true,
            internalType: 'address',
            name: 'newOwner',
            type: 'address',
          },
        ],
        name: 'OwnershipTransferred',
        type: 'event',
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: false,
            internalType: 'uint256',
            name: 'reward',
            type: 'uint256',
          },
        ],
        name: 'RewardAdded',
        type: 'event',
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: 'address',
            name: 'user',
            type: 'address',
          },
          {
            indexed: false,
            internalType: 'uint256',
            name: 'reward',
            type: 'uint256',
          },
        ],
        name: 'RewardPaid',
        type: 'event',
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: 'address',
            name: 'user',
            type: 'address',
          },
          {
            indexed: false,
            internalType: 'uint256',
            name: 'amount',
            type: 'uint256',
          },
        ],
        name: 'Staked',
        type: 'event',
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: 'address',
            name: 'user',
            type: 'address',
          },
          {
            indexed: false,
            internalType: 'uint256',
            name: 'amount',
            type: 'uint256',
          },
        ],
        name: 'Withdrawn',
        type: 'event',
      },
      {
        constant: true,
        inputs: [],
        name: 'DURATION',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: true,
        inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
        name: 'balanceOf',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: true,
        inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
        name: 'earned',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: false,
        inputs: [],
        name: 'exit',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        constant: false,
        inputs: [],
        name: 'getReward',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        constant: true,
        inputs: [],
        name: 'isOwner',
        outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: true,
        inputs: [],
        name: 'lastTimeRewardApplicable',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: true,
        inputs: [],
        name: 'lastUpdateTime',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: false,
        inputs: [{ internalType: 'uint256', name: 'reward', type: 'uint256' }],
        name: 'notifyRewardAmount',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        constant: true,
        inputs: [],
        name: 'owner',
        outputs: [{ internalType: 'address', name: '', type: 'address' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: true,
        inputs: [],
        name: 'periodFinish',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: false,
        inputs: [],
        name: 'renounceOwnership',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        constant: true,
        inputs: [],
        name: 'rewardDistribution',
        outputs: [{ internalType: 'address', name: '', type: 'address' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: true,
        inputs: [],
        name: 'rewardPerToken',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: true,
        inputs: [],
        name: 'rewardPerTokenStored',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: true,
        inputs: [],
        name: 'rewardRate',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: true,
        inputs: [{ internalType: 'address', name: '', type: 'address' }],
        name: 'rewards',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: false,
        inputs: [
          {
            internalType: 'address',
            name: '_rewardDistribution',
            type: 'address',
          },
        ],
        name: 'setRewardDistribution',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        constant: false,
        inputs: [{ internalType: 'uint256', name: 'amount', type: 'uint256' }],
        name: 'stake',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        constant: true,
        inputs: [],
        name: 'sebi',
        outputs: [
          { internalType: 'contract IERC20', name: '', type: 'address' },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: true,
        inputs: [],
        name: 'sebiinlp',
        outputs: [
          { internalType: 'contract IERC20', name: '', type: 'address' },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: true,
        inputs: [],
        name: 'totalSupply',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: false,
        inputs: [
          { internalType: 'address', name: 'newOwner', type: 'address' },
        ],
        name: 'transferOwnership',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        constant: true,
        inputs: [{ internalType: 'address', name: '', type: 'address' }],
        name: 'userRewardPerTokenPaid',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: false,
        inputs: [{ internalType: 'uint256', name: 'amount', type: 'uint256' }],
        name: 'withdraw',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
      },
    ]

    const contractpool = new web3.eth.Contract(
      abipool,
      'sebi0x4Fbca39bB61951b46Ac160930583578f5AF4C162',
    )

    const abillp = [
      {
        inputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'constructor',
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: 'address',
            name: 'owner',
            type: 'address',
          },
          {
            indexed: true,
            internalType: 'address',
            name: 'spender',
            type: 'address',
          },
          {
            indexed: false,
            internalType: 'uint256',
            name: 'value',
            type: 'uint256',
          },
        ],
        name: 'Approval',
        type: 'event',
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: 'address',
            name: 'sender',
            type: 'address',
          },
          {
            indexed: false,
            internalType: 'uint256',
            name: 'amount0',
            type: 'uint256',
          },
          {
            indexed: false,
            internalType: 'uint256',
            name: 'amount1',
            type: 'uint256',
          },
          {
            indexed: true,
            internalType: 'address',
            name: 'to',
            type: 'address',
          },
        ],
        name: 'Burn',
        type: 'event',
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: 'address',
            name: 'sender',
            type: 'address',
          },
          {
            indexed: false,
            internalType: 'uint256',
            name: 'amount0',
            type: 'uint256',
          },
          {
            indexed: false,
            internalType: 'uint256',
            name: 'amount1',
            type: 'uint256',
          },
        ],
        name: 'Mint',
        type: 'event',
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: 'address',
            name: 'sender',
            type: 'address',
          },
          {
            indexed: false,
            internalType: 'uint256',
            name: 'amount0In',
            type: 'uint256',
          },
          {
            indexed: false,
            internalType: 'uint256',
            name: 'amount1In',
            type: 'uint256',
          },
          {
            indexed: false,
            internalType: 'uint256',
            name: 'amount0Out',
            type: 'uint256',
          },
          {
            indexed: false,
            internalType: 'uint256',
            name: 'amount1Out',
            type: 'uint256',
          },
          {
            indexed: true,
            internalType: 'address',
            name: 'to',
            type: 'address',
          },
        ],
        name: 'Swap',
        type: 'event',
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: false,
            internalType: 'uint112',
            name: 'reserve0',
            type: 'uint112',
          },
          {
            indexed: false,
            internalType: 'uint112',
            name: 'reserve1',
            type: 'uint112',
          },
        ],
        name: 'Sync',
        type: 'event',
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: 'address',
            name: 'from',
            type: 'address',
          },
          {
            indexed: true,
            internalType: 'address',
            name: 'to',
            type: 'address',
          },
          {
            indexed: false,
            internalType: 'uint256',
            name: 'value',
            type: 'uint256',
          },
        ],
        name: 'Transfer',
        type: 'event',
      },
      {
        constant: true,
        inputs: [],
        name: 'DOMAIN_SEPARATOR',
        outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: true,
        inputs: [],
        name: 'MINIMUM_LIQUIDITY',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: true,
        inputs: [],
        name: 'PERMIT_TYPEHASH',
        outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: true,
        inputs: [
          { internalType: 'address', name: '', type: 'address' },
          { internalType: 'address', name: '', type: 'address' },
        ],
        name: 'allowance',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: false,
        inputs: [
          { internalType: 'address', name: 'spender', type: 'address' },
          { internalType: 'uint256', name: 'value', type: 'uint256' },
        ],
        name: 'approve',
        outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        constant: true,
        inputs: [{ internalType: 'address', name: '', type: 'address' }],
        name: 'balanceOf',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: false,
        inputs: [{ internalType: 'address', name: 'to', type: 'address' }],
        name: 'burn',
        outputs: [
          { internalType: 'uint256', name: 'amount0', type: 'uint256' },
          { internalType: 'uint256', name: 'amount1', type: 'uint256' },
        ],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        constant: true,
        inputs: [],
        name: 'decimals',
        outputs: [{ internalType: 'uint8', name: '', type: 'uint8' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: true,
        inputs: [],
        name: 'factory',
        outputs: [{ internalType: 'address', name: '', type: 'address' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: true,
        inputs: [],
        name: 'getReserves',
        outputs: [
          { internalType: 'uint112', name: '_reserve0', type: 'uint112' },
          { internalType: 'uint112', name: '_reserve1', type: 'uint112' },
          {
            internalType: 'uint32',
            name: '_blockTimestampLast',
            type: 'uint32',
          },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: false,
        inputs: [
          { internalType: 'address', name: '_token0', type: 'address' },
          { internalType: 'address', name: '_token1', type: 'address' },
        ],
        name: 'initialize',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        constant: true,
        inputs: [],
        name: 'kLast',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: false,
        inputs: [{ internalType: 'address', name: 'to', type: 'address' }],
        name: 'mint',
        outputs: [
          { internalType: 'uint256', name: 'liquidity', type: 'uint256' },
        ],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        constant: true,
        inputs: [],
        name: 'name',
        outputs: [{ internalType: 'string', name: '', type: 'string' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: true,
        inputs: [{ internalType: 'address', name: '', type: 'address' }],
        name: 'nonces',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: false,
        inputs: [
          { internalType: 'address', name: 'owner', type: 'address' },
          { internalType: 'address', name: 'spender', type: 'address' },
          { internalType: 'uint256', name: 'value', type: 'uint256' },
          { internalType: 'uint256', name: 'deadline', type: 'uint256' },
          { internalType: 'uint8', name: 'v', type: 'uint8' },
          { internalType: 'bytes32', name: 'r', type: 'bytes32' },
          { internalType: 'bytes32', name: 's', type: 'bytes32' },
        ],
        name: 'permit',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        constant: true,
        inputs: [],
        name: 'price0CumulativeLast',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: true,
        inputs: [],
        name: 'price1CumulativeLast',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: false,
        inputs: [{ internalType: 'address', name: 'to', type: 'address' }],
        name: 'skim',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        constant: false,
        inputs: [
          { internalType: 'uint256', name: 'amount0Out', type: 'uint256' },
          { internalType: 'uint256', name: 'amount1Out', type: 'uint256' },
          { internalType: 'address', name: 'to', type: 'address' },
          { internalType: 'bytes', name: 'data', type: 'bytes' },
        ],
        name: 'swap',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        constant: true,
        inputs: [],
        name: 'symbol',
        outputs: [{ internalType: 'string', name: '', type: 'string' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: false,
        inputs: [],
        name: 'sync',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        constant: true,
        inputs: [],
        name: 'token0',
        outputs: [{ internalType: 'address', name: '', type: 'address' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: true,
        inputs: [],
        name: 'token1',
        outputs: [{ internalType: 'address', name: '', type: 'address' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: true,
        inputs: [],
        name: 'totalSupply',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: false,
        inputs: [
          { internalType: 'address', name: 'to', type: 'address' },
          { internalType: 'uint256', name: 'value', type: 'uint256' },
        ],
        name: 'transfer',
        outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        constant: false,
        inputs: [
          { internalType: 'address', name: 'from', type: 'address' },
          { internalType: 'address', name: 'to', type: 'address' },
          { internalType: 'uint256', name: 'value', type: 'uint256' },
        ],
        name: 'transferFrom',
        outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
      },
    ]
    const contractlp = new web3.eth.Contract(
      abillp,
      '0x1ecdab40fe2424a511fd35250ddc37a0ae7c1bb9',
    )

    const abiftm = [
      {
        inputs: [
          {
            internalType: 'address',
            name: 'src',
            type: 'address',
            indexed: true,
          },
          {
            internalType: 'address',
            name: 'guy',
            indexed: true,
            type: 'address',
          },
          {
            indexed: false,
            name: 'wad',
            type: 'uint256',
            internalType: 'uint256',
          },
        ],
        anonymous: false,
        type: 'event',
        name: 'Approval',
      },
      {
        type: 'event',
        inputs: [
          {
            name: 'dst',
            internalType: 'address',
            type: 'address',
            indexed: true,
          },
          {
            type: 'uint256',
            name: 'wad',
            internalType: 'uint256',
            indexed: false,
          },
        ],
        name: 'Deposit',
        anonymous: false,
      },
      {
        anonymous: false,
        type: 'event',
        name: 'Transfer',
        inputs: [
          {
            name: 'src',
            indexed: true,
            type: 'address',
            internalType: 'address',
          },
          {
            internalType: 'address',
            indexed: true,
            type: 'address',
            name: 'dst',
          },
          {
            type: 'uint256',
            indexed: false,
            name: 'wad',
            internalType: 'uint256',
          },
        ],
      },
      {
        name: 'Withdrawal',
        inputs: [
          {
            type: 'address',
            indexed: true,
            internalType: 'address',
            name: 'src',
          },
          {
            internalType: 'uint256',
            name: 'wad',
            type: 'uint256',
            indexed: false,
          },
        ],
        anonymous: false,
        type: 'event',
      },
      {
        name: 'allowance',
        inputs: [
          { type: 'address', name: '', internalType: 'address' },
          { type: 'address', name: '', internalType: 'address' },
        ],
        type: 'function',
        outputs: [{ internalType: 'uint256', type: 'uint256', name: '' }],
        stateMutability: 'view',
      },
      {
        outputs: [{ type: 'uint256', internalType: 'uint256', name: '' }],
        name: 'balanceOf',
        stateMutability: 'view',
        inputs: [{ name: '', type: 'address', internalType: 'address' }],
        type: 'function',
      },
      {
        outputs: [{ name: '', type: 'uint8', internalType: 'uint8' }],
        stateMutability: 'view',
        inputs: [],
        name: 'decimals',
        type: 'function',
      },
      {
        inputs: [],
        outputs: [{ name: '', type: 'string', internalType: 'string' }],
        name: 'name',
        type: 'function',
        stateMutability: 'view',
      },
      {
        stateMutability: 'view',
        inputs: [],
        outputs: [{ name: '', type: 'string', internalType: 'string' }],
        name: 'symbol',
        type: 'function',
      },
      { stateMutability: 'payable', type: 'receive' },
      {
        name: 'deposit',
        outputs: [],
        stateMutability: 'payable',
        type: 'function',
        inputs: [],
      },
      {
        inputs: [{ name: 'wad', type: 'uint256', internalType: 'uint256' }],
        stateMutability: 'nonpayable',
        type: 'function',
        name: 'withdraw',
        outputs: [],
      },
      {
        outputs: [{ type: 'uint256', internalType: 'uint256', name: '' }],
        inputs: [],
        stateMutability: 'view',
        type: 'function',
        name: 'totalSupply',
      },
      {
        type: 'function',
        outputs: [{ name: '', type: 'bool', internalType: 'bool' }],
        name: 'approve',
        stateMutability: 'nonpayable',
        inputs: [
          { name: 'guy', internalType: 'address', type: 'address' },
          { name: 'wad', type: 'uint256', internalType: 'uint256' },
        ],
      },
      {
        inputs: [
          { name: 'dst', type: 'address', internalType: 'address' },
          { name: 'wad', type: 'uint256', internalType: 'uint256' },
        ],
        name: 'transfer',
        stateMutability: 'nonpayable',
        outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
        type: 'function',
      },
      {
        type: 'function',
        name: 'transferFrom',
        inputs: [
          { type: 'address', internalType: 'address', name: 'src' },
          { type: 'address', name: 'dst', internalType: 'address' },
          { name: 'wad', type: 'uint256', internalType: 'uint256' },
        ],
        stateMutability: 'nonpayable',
        outputs: [{ type: 'bool', internalType: 'bool', name: '' }],
      },
    ]

    const contractftm = new web3.eth.Contract(
      abiftm,
      '0x7466d7d0C21Fa05F32F5a0Fa27e12bdC06348Ce2',
    )

    this.setState({ contractlp })

    this.setState({ contractftm })

    this.setState({ contractpool })

    this.setState({ contractblack })

    this.setState({ contractlike })

    // console.log(contract)
    this.setState({ contractav })
    // console.log(contract)
    this.setState({ contractv })

    this.setState({ contract })

    const totalSupply = await contract.methods.totalSupply().call()
    // console.log(totalSupply)
    this.setState({ totalSupply })

    var SI_SYMBOL = ['', 'k', 'M', 'B', 'T', 'P', 'E']

    function abbreviateNumber(number) {
      // what tier? (determines SI symbol)
      var tier = (Math.log10(Math.abs(number)) / 3) | 0

      // if zero, we don't need a suffix
      if (tier == 0) return number

      // get suffix and determine scale
      var suffix = SI_SYMBOL[tier]
      var scale = Math.pow(10, tier * 3)

      // scale the number
      var scaled = number / scale

      // format number and add suffix
      return scaled.toFixed(1) + suffix
    }

    const rewardRate = await contractpool.methods.rewardRate().call()
    const reward = await contractpool.methods.rewards(this.state.account).call()
    const rewards = reward / 1e18
    const earn = await contractpool.methods.earned(this.state.account).call()
    const earned = earn / 1e18
    const rewardbal = earned * sebiusd
    const balanceInPool = await contractpool.methods
      .balanceOf(this.state.account)
      .call()
    const balPool = balanceInPool / 1e18
    const totalinPool = await contractpool.methods.totalSupply().call()
    const bal = await contractmain.methods.balanceOf(this.state.account).call()
    const balance = bal / 1e18
    const usdbal = balance * sebiusd
    const balLP = await contractlp.methods.balanceOf(this.state.account).call()
    const balanceLP = Number(web3.utils.fromWei(balLP))
    const totalLP = await contractlp.methods.totalSupply().call()
    const percentageLP = Number((balLP / totalLP) * 100).toFixed(2)
    const percentagePool = Number((balanceInPool / totalinPool) * 100).toFixed(
      2,
    )

    const balFTMlp = await contractftm.methods
      .balanceOf('0x1ecdab40fe2424a511fd35250ddc37a0ae7c1bb9')
      .call()

    const totalliquidity = (balFTMlp / 1e18) * ftmprice * 2

    const approval = await contractlp.methods
      .allowance(
        this.state.account,
        'sebi0x4Fbca39bB61951b46Ac160930583578f5AF4C162',
      )
      .call()

    if (approval > 900000000000000) {
      this.setState({ approved: true })
    }

    this.setState({ rewardRate })
    this.setState({ rewards })
    this.setState({ earned })
    this.setState({ balanceInPool })
    this.setState({ balPool })
    this.setState({ balance })
    this.setState({ balanceLP })
    this.setState({ totalLP })
    this.setState({ percentageLP })
    this.setState({ percentagePool })
    this.setState({ usdbal })
    this.setState({ totalliquidity })
    this.setState({ rewardbal })

    setInterval(async () => {
      const rewardRate = await contractpool.methods.rewardRate().call()
      const reward = await contractpool.methods
        .rewards(this.state.account)
        .call()
      const rewards = reward / 1e18
      const earn = await contractpool.methods.earned(this.state.account).call()
      const earned = earn / 1e18
      const rewardbal = earned * sebiusd
      const balanceInPool = await contractpool.methods
        .balanceOf(this.state.account)
        .call()
      const balPool = balanceInPool / 1e18
      const totalinPool = await contractpool.methods.totalSupply().call()
      const bal = await contractmain.methods
        .balanceOf(this.state.account)
        .call()
      const balance = bal / 1e18
      const usdbal = balance * sebiusd
      const balLP = await contractlp.methods
        .balanceOf(this.state.account)
        .call()
      const balanceLP = balLP / 1e18
      const totalLP = await contractlp.methods.totalSupply().call()
      const percentageLP = Number((balLP / totalLP) * 100).toFixed(2)
      const percentagePool = Number(
        (balanceInPool / totalinPool) * 100,
      ).toFixed(2)

      const balFTMlp = await contractftm.methods
        .balanceOf('0x1ecdab40fe2424a511fd35250ddc37a0ae7c1bb9')
        .call()

      const totalliquidity = (balFTMlp / 1e18) * ftmprice * 2

      this.setState({ rewardRate })
      this.setState({ rewards })
      this.setState({ earned })
      this.setState({ balanceInPool })
      this.setState({ balPool })
      this.setState({ balance })
      this.setState({ balanceLP })
      this.setState({ totalLP })
      this.setState({ percentageLP })
      this.setState({ percentagePool })
      this.setState({ usdbal })
      this.setState({ totalliquidity })
      this.setState({ rewardbal })
    }, 5000)

    if (this.state.approved === true) {
      document.getElementById('approval').style.display = 'none'
      document.getElementById('deposit').style.display = 'block'
      document.getElementById('withdraw').style.display = 'block'
      document.getElementById('claim').style.display = 'block'
    }
  }
}

export default Stake
