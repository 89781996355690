import React from 'react'
import ReactDOM from 'react-dom'

import './index.css'
import App from './components/App'
// import * as serviceWorker from './worker';

import { unregister as unregisterServiceWorker } from './worker'

ReactDOM.render(
  <React.Fragment>
    <App />
  </React.Fragment>,
  document.getElementById('root'),
)

// serviceWorker.register();
unregisterServiceWorker()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
